export const GET_MY_QUICK_NOTE = 'GET_MY_QUICK_NOTE'
export const GET_CARE_LOCATION_QUICK_NOTE = 'GET_CARE_LOCATION_QUICK_NOTE'
export const GET_ARCHIVED_QUICK_NOTE = 'GET_ARCHIVED_QUICK_NOTE'
export const SET_MY_QUICK_NOTE = 'SET_MY_QUICK_NOTE'
export const SET_CARE_LOCATION_QUICK_NOTE = 'SET_CARE_LOCATION_QUICK_NOTE'
export const SET_ARCHIVED_QUICK_NOTE = 'SET_ARCHIVED_QUICK_NOTE'
export const ADD_CARE_LOCATION_QUICK_NOTE = 'ADD_CARE_LOCATION_QUICK_NOTE'
export const SET_CARE_LOCATION_TOTAL = 'SET_CARE_LOCATION_TOTAL'
export const EDIT_QUICK_NOTE = 'EDIT_QUICK_NOTE'
export const CREATE_QUICK_NOTE = 'CREATE_QUICK_NOTE'
export const GET_SINGLE_QUICK_NOTE = 'GET_SINGLE_QUICK_NOTE'
export const SET_CARE_LOCATION_QUICK_NOTE_TOTAL = 'SET_CARE_LOCATION_QUICK_NOTE_TOTAL'
export const ARCHIVE_QUICK_NOTE = 'ARCHIVE_QUICK_NOTE'
export const UNARCHIVE_QUICK_NOTE = 'UNARCHIVE_QUICK_NOTE'
export const GET_QUICK_NOTE_RECORDS = 'GET_QUICK_NOTE_RECORDS'