<template>
    <div id="app" class='app' :class='popUpActive()'>
        <navigation></navigation>
        <router-view/>
        <!-- <footer-view></footer-view> -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Navigation from '@/components/nav/Navigation'
    // import FooterView from '@/components/nav/FooterView'
    export default {
        name: 'app',
        components: {
            Navigation,
            // FooterView
        },
        computed: {
            ...mapGetters([
                'popUp'
            ])
        },
        methods: {
            popUpActive() {
                if (this.popUp) {
                    return '-pop-up-holder'
                }
                return ''
            }
        }
    }
</script>

 