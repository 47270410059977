export const GET_MY_TODO = 'GET_MY_TODO'
export const GET_CARE_LOCATION_TODO = 'GET_CARE_LOCATION_TODO'
export const GET_MY_COMPLETED_TODO = 'GET_MY_COMPLETED_TODO'
export const SET_MY_TODO = 'SET_MY_TODO'
export const SET_CARE_LOCATION_TODO = 'SET_CARE_LOCATION_TODO'
export const SET_MY_COMPLETED_TODO = 'SET_MY_COMPLETED_TODO'
export const ADD_CARE_LOCATION_TODO = 'ADD_CARE_LOCATION_TODO'
export const SET_CARE_LOCATION_TODO_TOTAL = 'SET_CARE_LOCATION_TODO_TOTAL'
export const EDIT_TODO = 'EDIT_TODO'
// export const EDIT_MY_TODO = 'EDIT_MY_TODO'
// export const EDIT_CARE_LOCATION_TODO = 'EDIT_CARE_LOCATION_TODO'
// export const EDIT_COMPLETED_TODO = 'EDIT_COMPLETED_TODO'
export const DELETE_TODO = 'DELETE_TODO'
export const CLEAN_COMPLETED_TO_DO = 'CLEAN_COMPLETED_TO_DO'
export const CREATE_TODO = 'CREATE_TODO'
export const GET_SINGLE_TODO = 'GET_SINGLE_TODO'