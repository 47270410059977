export default {
    state: {
        pageTransition: 'slideFade',
        popUpTransition: 'popUp',
        popUp: false,
        homeTransition: 'popStay',
    },
    getters: {
        pageTransition: state => {
            return state.pageTransition
        },
        popUpTransition: state => {
            return state.popUpTransition
        },
        popUp: state => {
            return state.popUp
        },
        homeTransition: state => {
            return state.homeTransition
        }
    },
    mutations: {
        setPageTransition: (state, payload) => {
            state.pageTransition = payload
        },
        setPopUpTransition: (state, payload) => {
            state.popUpTransition = payload
        },
        setPopUp: (state, payload) => {
            state.popUp = payload
        },
        setHomeTransition: (state, payload) => {
            state.homeTransition = payload
        }
    },
    actions: {
        setPageTransition: ({commit}, payload) => {
            commit('setPageTransition', payload)
        },
        setPopUpTransition: ({commit}, payload) => {
            commit('setPopUpTransition', payload)
        },
        setPopUp: ({commit}, payload) => {
            commit('setPopUp', payload)
        },
        setHomeTransition: ({commit}, payload) => {
            commit('setHomeTransition', payload)
        }
    }
}