export default {
    state: {
        languageCodes: [
            {'description': 'No interpreter required', 'code': 0},
            {'description': 'Abkhazian', 'code': 'ab'},
            {'description': 'Afar', 'code': 'aa'},
            {'description': 'Afrikaans', 'code': 'af'},
            {'description': 'Akan', 'code': 'ak'},
            {'description': 'Albanian', 'code': 'sq'},
            {'description': 'Amharic', 'code': 'am'},
            {'description': 'Arabic', 'code': 'ar'},
            {'description': 'Aragonese', 'code': 'an'},
            {'description': 'Armenian', 'code': 'hy'},
            {'description': 'Assamese', 'code': 'as'},
            {'description': 'Avaric', 'code': 'av'},
            {'description': 'Avestan', 'code': 'ae'},
            {'description': 'Aymara', 'code': 'ay'},
            {'description': 'Azerbaijani', 'code': 'az'},
            {'description': 'Bambara', 'code': 'bm'},
            {'description': 'Bashkir', 'code': 'ba'},
            {'description': 'Basque', 'code': 'eu'},
            {'description': 'Belarusian', 'code': 'be'},
            {'description': 'Bengali', 'code': 'bn'},
            {'description': 'Bihari languages', 'code': 'bh'},
            {'description': 'Bislama', 'code': 'bi'},
            {'description': 'Bosnian', 'code': 'bs'},
            {'description': 'Breton', 'code': 'br'},
            {'description': 'Bulgarian', 'code': 'bg'},
            {'description': 'Burmese', 'code': 'my'},
            {'description': 'Catalan, Valencian', 'code': 'ca'},
            {'description': 'Chamorro', 'code': 'ch'},
            {'description': 'Chechen', 'code': 'ce'},
            {'description': 'Chichewa, Chewa, Nyanja', 'code': 'ny'},
            {'description': 'Chinese', 'code': 'zh'},
            {'description': 'Chuvash', 'code': 'cv'},
            {'description': 'Cornish', 'code': 'kw'},
            {'description': 'Corsican', 'code': 'co'},
            {'description': 'Cree', 'code': 'cr'},
            {'description': 'Croatian', 'code': 'hr'},
            {'description': 'Czech', 'code': 'cs'},
            {'description': 'Danish', 'code': 'da'},
            {'description': 'Divehi, Dhivehi, Maldivian', 'code': 'dv'},
            {'description': 'Dutch, Flemish', 'code': 'nl'},
            {'description': 'Dzongkha', 'code': 'dz'},
            {'description': 'English', 'code': 'en'},
            {'description': 'Esperanto', 'code': 'eo'},
            {'description': 'Estonian', 'code': 'et'},
            {'description': 'Ewe', 'code': 'ee'},
            {'description': 'Faroese', 'code': 'fo'},
            {'description': 'Fijian', 'code': 'fj'},
            {'description': 'Finnish', 'code': 'fi'},
            {'description': 'French', 'code': 'fr'},
            {'description': 'Fulah', 'code': 'ff'},
            {'description': 'Galician', 'code': 'gl'},
            {'description': 'Georgian', 'code': 'ka'},
            {'description': 'German', 'code': 'de'},
            {'description': 'Greek, Modern (1453–)', 'code': 'el'},
            {'description': 'Guarani', 'code': 'gn'},
            {'description': 'Gujarati', 'code': 'gu'},
            {'description': 'Haitian, Haitian Creole', 'code': 'ht'},
            {'description': 'Hausa', 'code': 'ha'},
            {'description': 'Hebrew', 'code': 'he'},
            {'description': 'Herero', 'code': 'hz'},
            {'description': 'Hindi', 'code': 'hi'},
            {'description': 'Hiri Motu', 'code': 'ho'},
            {'description': 'Hungarian', 'code': 'hu'},
            {'description': 'Interlingua (International Auxiliary Language Association)', 'code': 'ia'},
            {'description': 'Indonesian', 'code': 'id'},
            {'description': 'Interlingue, Occidental', 'code': 'ie'},
            {'description': 'Irish', 'code': 'ga'},
            {'description': 'Igbo', 'code': 'ig'},
            {'description': 'Inupiaq', 'code': 'ik'},
            {'description': 'Ido', 'code': 'io'},
            {'description': 'Icelandic', 'code': 'is'},
            {'description': 'Italian', 'code': 'it'},
            {'description': 'Inuktitut', 'code': 'iu'},
            {'description': 'Japanese', 'code': 'ja'},
            {'description': 'Javanese', 'code': 'jv'},
            {'description': 'Kalaallisut, Greenlandic', 'code': 'kl'},
            {'description': 'Kannada', 'code': 'kn'},
            {'description': 'Kanuri', 'code': 'kr'},
            {'description': 'Kashmiri', 'code': 'ks'},
            {'description': 'Kazakh', 'code': 'kk'},
            {'description': 'Central Khmer', 'code': 'km'},
            {'description': 'Kikuyu, Gikuyu', 'code': 'ki'},
            {'description': 'Kinyarwanda', 'code': 'rw'},
            {'description': 'Kirghiz, Kyrgyz', 'code': 'ky'},
            {'description': 'Komi', 'code': 'kv'},
            {'description': 'Kongo', 'code': 'kg'},
            {'description': 'Korean', 'code': 'ko'},
            {'description': 'Kurdish', 'code': 'ku'},
            {'description': 'Kuanyama, Kwanyama', 'code': 'kj'},
            {'description': 'Latin', 'code': 'la'},
            {'description': 'Luxembourgish, Letzeburgesch', 'code': 'lb'},
            {'description': 'Ganda', 'code': 'lg'},
            {'description': 'Limburgan, Limburger, Limburgish', 'code': 'li'},
            {'description': 'Lingala', 'code': 'ln'},
            {'description': 'Lao', 'code': 'lo'},
            {'description': 'Lithuanian', 'code': 'lt'},
            {'description': 'Luba-Katanga', 'code': 'lu'},
            {'description': 'Latvian', 'code': 'lv'},
            {'description': 'Manx', 'code': 'gv'},
            {'description': 'Macedonian', 'code': 'mk'},
            {'description': 'Malagasy', 'code': 'mg'},
            {'description': 'Malay', 'code': 'ms'},
            {'description': 'Malayalam', 'code': 'ml'},
            {'description': 'Maltese', 'code': 'mt'},
            {'description': 'Maori', 'code': 'mi'},
            {'description': 'Marathi', 'code': 'mr'},
            {'description': 'Marshallese', 'code': 'mh'},
            {'description': 'Mongolian', 'code': 'mn'},
            {'description': 'Nauru', 'code': 'na'},
            {'description': 'Navajo, Navaho', 'code': 'nv'},
            {'description': 'North Ndebele', 'code': 'nd'},
            {'description': 'Nepali', 'code': 'ne'},
            {'description': 'Ndonga', 'code': 'ng'},
            {'description': 'Norwegian Bokmål', 'code': 'nb'},
            {'description': 'Norwegian Nynorsk', 'code': 'nn'},
            {'description': 'Norwegian', 'code': 'no'},
            {'description': 'Sichuan Yi, Nuosu', 'code': 'ii'},
            {'description': 'South Ndebele', 'code': 'nr'},
            {'description': 'Occitan', 'code': 'oc'},
            {'description': 'Ojibwa', 'code': 'oj'},
            {'description': 'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic', 'code': 'cu'},
            {'description': 'Oromo', 'code': 'om'},
            {'description': 'Oriya', 'code': 'or'},
            {'description': 'Ossetian, Ossetic', 'code': 'os'},
            {'description': 'Punjabi, Panjabi', 'code': 'pa'},
            {'description': 'Pali', 'code': 'pi'},
            {'description': 'Persian', 'code': 'fa'},
            {'description': 'Polish', 'code': 'pl'},
            {'description': 'Pashto, Pushto', 'code': 'ps'},
            {'description': 'Portuguese', 'code': 'pt'},
            {'description': 'Quechua', 'code': 'qu'},
            {'description': 'Romansh', 'code': 'rm'},
            {'description': 'Rundi', 'code': 'rn'},
            {'description': 'Romanian, Moldavian, Moldovan', 'code': 'ro'},
            {'description': 'Russian', 'code': 'ru'},
            {'description': 'Sanskrit', 'code': 'sa'},
            {'description': 'Sardinian', 'code': 'sc'},
            {'description': 'Sindhi', 'code': 'sd'},
            {'description': 'Northern Sami', 'code': 'se'},
            {'description': 'Samoan', 'code': 'sm'},
            {'description': 'Sango', 'code': 'sg'},
            {'description': 'Serbian', 'code': 'sr'},
            {'description': 'Gaelic, Scottish Gaelic', 'code': 'gd'},
            {'description': 'Shona', 'code': 'sn'},
            {'description': 'Sinhala, Sinhalese', 'code': 'si'},
            {'description': 'Slovak', 'code': 'sk'},
            {'description': 'Slovenian', 'code': 'sl'},
            {'description': 'Somali', 'code': 'so'},
            {'description': 'Southern Sotho', 'code': 'st'},
            {'description': 'Spanish, Castilian', 'code': 'es'},
            {'description': 'Sundanese', 'code': 'su'},
            {'description': 'Swahili', 'code': 'sw'},
            {'description': 'Swati', 'code': 'ss'},
            {'description': 'Swedish', 'code': 'sv'},
            {'description': 'Tamil', 'code': 'ta'},
            {'description': 'Telugu', 'code': 'te'},
            {'description': 'Tajik', 'code': 'tg'},
            {'description': 'Thai', 'code': 'th'},
            {'description': 'Tigrinya', 'code': 'ti'},
            {'description': 'Tibetan', 'code': 'bo'},
            {'description': 'Turkmen', 'code': 'tk'},
            {'description': 'Tagalog', 'code': 'tl'},
            {'description': 'Tswana', 'code': 'tn'},
            {'description': 'Tonga (Tonga Islands)', 'code': 'to'},
            {'description': 'Turkish', 'code': 'tr'},
            {'description': 'Tsonga', 'code': 'ts'},
            {'description': 'Tatar', 'code': 'tt'},
            {'description': 'Twi', 'code': 'tw'},
            {'description': 'Tahitian', 'code': 'ty'},
            {'description': 'Uighur, Uyghur', 'code': 'ug'},
            {'description': 'Ukrainian', 'code': 'uk'},
            {'description': 'Urdu', 'code': 'ur'},
            {'description': 'Uzbek', 'code': 'uz'},
            {'description': 'Venda', 'code': 've'},
            {'description': 'Vietnamese', 'code': 'vi'},
            {'description': 'Volapük', 'code': 'vo'},
            {'description': 'Walloon', 'code': 'wa'},
            {'description': 'Welsh', 'code': 'cy'},
            {'description': 'Wolof', 'code': 'wo'},
            {'description': 'Western Frisian', 'code': 'fy'},
            {'description': 'Xhosa', 'code': 'xh'},
            {'description': 'Yiddish', 'code': 'yi'},
            {'description': 'Yoruba', 'code': 'yo'},
            {'description': 'Zhuang, Chuang', 'code': 'za'},
            {'description': 'Zulu', 'code': 'zu'},
        ]
    },
    getters: {
        languageCodes: state => {
            return state.languageCodes
        }
    }
}