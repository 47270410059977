export default {
    state: {
        ethnicityCodes: [
            {'code': '37111', 'description': 'Admiralty Islander'},
            {'code': '44411', 'description': 'Afghani'},
            {'code': '53', 'description': 'African'},
            {'code': '53116', 'description': 'African American'},
            {'code': '53100', 'description': 'African nfd'},
            {'code': '32111', 'description': 'Aitutaki Islander'},
            {'code': '12911', 'description': 'Albanian'},
            {'code': '51111', 'description': 'Algerian'},
            {'code': '12943', 'description': 'American (US)'},
            {'code': '51112', 'description': 'Arab'},
            {'code': '52111', 'description': 'Argentinian'},
            {'code': '12912', 'description': 'Armenian'},
            {'code': '4', 'description': 'Asian'},
            {'code': '40', 'description': 'Asian not further defined'},
            {'code': '51113', 'description': 'Assyrian'},
            {'code': '32112', 'description': 'Atiu Islander'},
            {'code': '37113', 'description': 'Austral Islander'},
            {'code': '128', 'description': 'Australian'},
            {'code': '37112', 'description': 'Australian Aboriginal'},
            {'code': '12913', 'description': 'Austrian'},
            {'code': '44412', 'description': 'Bangladeshi'},
            {'code': '37114', 'description': 'Belau/Palau Islander'},
            {'code': '12914', 'description': 'Belgian'},
            {'code': '43111', 'description': 'Bengali'},
            {'code': '37115', 'description': 'Bismark Archipelagoan'},
            {'code': '53111', 'description': 'Black'},
            {'code': '52112', 'description': 'Bolivian'},
            {'code': '37116', 'description': 'Bougainvillean'},
            {'code': '52113', 'description': 'Brazilian'},
            {'code': '121', 'description': 'British and Irish'},
            {'code': '12199', 'description': 'British NEC'},
            {'code': '12100', 'description': 'British nfd'},
            {'code': '12915', 'description': 'Bulgarian'},
            {'code': '12944', 'description': 'Burgher'},
            {'code': '41411', 'description': 'Burmese'},
            {'code': '12916', 'description': 'Byelorussian'},
            {'code': '12945', 'description': 'Canadian'},
            {'code': '37117', 'description': 'Caroline Islander'},
            {'code': '12111', 'description': 'Celtic'},
            {'code': '54111', 'description': 'Central American Indian'},
            {'code': '12112', 'description': 'Channel Islander'},
            {'code': '52114', 'description': 'Chilean'},
            {'code': '42', 'description': 'Chinese'},
            {'code': '42199', 'description': 'Chinese nec'},
            {'code': '42100', 'description': 'Chinese nfd'},
            {'code': '52115', 'description': 'Colombian'},
            {'code': '32', 'description': 'Cook Island Mâori'},
            {'code': '32100', 'description': 'Cook Island Maori nfd'},
            {'code': '12113', 'description': 'Cornish'},
            {'code': '12917', 'description': 'Corsican'},
            {'code': '52116', 'description': 'Costa Rican'},
            {'code': '52117', 'description': 'Creole (Latin America)'},
            {'code': '53112', 'description': 'Creole (US)'},
            {'code': '12511', 'description': 'Croat/Croatian'},
            {'code': '12918', 'description': 'Cypriot Unspecified'},
            {'code': '12919', 'description': 'Czech'},
            {'code': '12512', 'description': 'Dalmatian'},
            {'code': '12920', 'description': 'Danish'},
            {'code': '98', 'description': 'Declined to Stated'},
            {'code': '94', 'description': 'Don\'t know'},
            {'code': '122', 'description': 'Dutch'},
            {'code': '12211', 'description': 'Dutch/Netherlands'},
            {'code': '37118', 'description': 'Easter Islander'},
            {'code': '52118', 'description': 'Ecuadorian'},
            {'code': '51114', 'description': 'Egyptian'},
            {'code': '12114', 'description': 'English'},
            {'code': '12921', 'description': 'Estonian'},
            {'code': '1', 'description': 'European'},
            {'code': '12999', 'description': 'European nec'},
            {'code': '10', 'description': 'European not further defined'},
            {'code': '12946', 'description': 'Falkland Islander/Kelper'},
            {'code': '36', 'description': 'Fijian'},
            {'code': '36111', 'description': 'Fijian (except Fiji Indian/Indo-Fijian)'},
            {'code': '43112', 'description': 'Fijian Indian/Indo-Fijian'},
            {'code': '411', 'description': 'Filipino'},
            {'code': '12922', 'description': 'Finnish'},
            {'code': '12923', 'description': 'Flemish'},
            {'code': '12924', 'description': 'French'},
            {'code': '12115', 'description': 'Gaelic'},
            {'code': '37119', 'description': 'Gambier Islander'},
            {'code': '127', 'description': 'German'},
            {'code': '123', 'description': 'Greek (incl Greek Cypriot)'},
            {'code': '12925', 'description': 'Greenlander'},
            {'code': '37120', 'description': 'Guadalcanalian'},
            {'code': '37121', 'description': 'Guam Islander/Chamorro'},
            {'code': '52119', 'description': 'Guatemalan'},
            {'code': '43113', 'description': 'Gujarati'},
            {'code': '52120', 'description': 'Guyanese'},
            {'code': '37122', 'description': 'Hawaiian'},
            {'code': '52121', 'description': 'Honduran'},
            {'code': '42111', 'description': 'Hong Kong Chinese'},
            {'code': '12926', 'description': 'Hungarian'},
            {'code': '37124', 'description': 'I-Kiribati/Gilbertese'},
            {'code': '12927', 'description': 'Icelander'},
            {'code': '43', 'description': 'Indian'},
            {'code': '43199', 'description': 'Indian nec'},
            {'code': '43100', 'description': 'Indian nfd'},
            {'code': '41412', 'description': 'Indonesian (incl Javanese/Sundanese/Sumatran)'},
            {'code': '54112', 'description': 'Inuit/Eskimo'},
            {'code': '51115', 'description': 'Iranian/Persian'},
            {'code': '51116', 'description': 'Iraqi'},
            {'code': '12116', 'description': 'Irish'},
            {'code': '51117', 'description': 'Israeli/Jewish/Hebrew'},
            {'code': '126', 'dscription': 'Italian'},
            {'code': '53113', 'description': 'Jamaican'},
            {'code': '442', 'description': 'Japanese'},
            {'code': '51118', 'description': 'Jordanian'},
            {'code': '42112', 'description': 'Kampuchean Chinese'},
            {'code': '37123', 'description': 'Kanaka/Kanak'},
            {'code': '53114', 'description': 'Kenyan'},
            {'code': '412', 'description': 'Khmer/Kampuchean/Cambodian'},
            {'code': '443', 'description': 'Korean'},
            {'code': '51119', 'description': 'Kurd'},
            {'code': '41413', 'description': 'Lao/Laotian'},
            {'code': '52', 'description': 'Latin American/Hispanic'},
            {'code': '52199', 'description': 'Latin American/Hispanic nec'},
            {'code': '52100', 'description': 'Latin American/Hispanic nfd'},
            {'code': '12928', 'description': 'Latvian'},
            {'code': '51120', 'description': 'Lebanese'},
            {'code': '51121', 'description': 'Libyan'},
            {'code': '12929', 'description': 'Lithuanian'},
            {'code': '12513', 'description': 'Macedonian'},
            {'code': '37125', 'description': 'Malaitian'},
            {'code': '41414', 'description': 'Malay/Malayan'},
            {'code': '42113', 'description': 'Malaysian Chinese'},
            {'code': '12930', 'description': 'Maltese'},
            {'code': '52122', 'description': 'Malvinian (Spanish-speaking Falkland Islander)'},
            {'code': '32113', 'description': 'Mangaia Islander'},
            {'code': '32114', 'description': 'Manihiki Islander'},
            {'code': '37126', 'description': 'Manus Islander'},
            {'code': '12117', 'description': 'Manx'},
            {'code': '37127', 'description': 'Marianas Islander'},
            {'code': '37128', 'description': 'Marquesas Islander'},
            {'code': '37129', 'description': 'Marshall Islander'},
            {'code': '32115', 'description': 'Mauke Islander'},
            {'code': '54113', 'description': 'Mauritian'},
            {'code': '52123', 'description': 'Mexican'},
            {'code': '51', 'description': 'Middle Eastern'},
            {'code': '51199', 'description': 'Middle Eastern nec'},
            {'code': '51100', 'description': 'Middle Eastern nfd'},
            {'code': '32116', 'description': 'Mitiaro Islander'},
            {'code': '51122', 'description': 'Moroccan'},
            {'code': '37130', 'description': 'Nauru Islander'},
            {'code': '44413', 'description': 'Nepalese'},
            {'code': '37131', 'description': 'New Britain Islander'},
            {'code': '12947', 'description': 'New Caledonian'},
            {'code': '37132', 'description': 'New Georgian'},
            {'code': '37133', 'description': 'New Irelander'},
            {'code': '52124', 'description': 'Nicaraguan'},
            {'code': '53115', 'description': 'Nigerian'},
            {'code': '34', 'description': 'Niuean'},
            {'code': '54114', 'description': 'North American Indian'},
            {'code': '12931', 'description': 'Norwegian'},
            {'code': '99', 'description': 'Not Specified'},
            {'code': '11', 'description': 'NZ European / Pakeha'},
            {'code': '21', 'description': 'NZ Maori'},
            {'code': '37134', 'description': 'Ocean Islander/Banaban'},
            {'code': '51123', 'description': 'Omani'},
            {'code': '12118', 'description': 'Orkney Islander'},
            {'code': '54', 'description': 'Other'},
            {'code': '53199', 'description': 'Other African nec'},
            {'code': '44', 'description': 'Other Asian'},
            {'code': '44499', 'description': 'Other Asian nec'},
            {'code': '5', 'description': 'Other Ethnic Groups'},
            {'code': '61', 'description': 'Other Ethnicity'},
            {'code': '12', 'description': 'Other European'},
            {'code': '120', 'description': 'Other European nfd'},
            {'code': '54199', 'description': 'Other nec'},
            {'code': '54100', 'description': 'Other nfd'},
            {'code': '37', 'description': 'Other Pacific Island (not listed)'},
            {'code': '37100', 'description': 'Other Pacific Island Groups nfd'},
            {'code': '37199', 'description': 'Other Pacific Island nec'},
            {'code': '414', 'description': 'Other Southeast Asian'},
            {'code': '41499', 'description': 'Other Southeast Asian nec'},
            {'code': '3', 'description': 'Pacific Island'},
            {'code': '30', 'description': 'Pacific Island not further defined'},
            {'code': '44414', 'description': 'Pakistani'},
            {'code': '51124', 'description': 'Palestinian'},
            {'code': '32117', 'description': 'Palmerston Islander'},
            {'code': '52125', 'description': 'Panamanian'},
            {'code': '37135', 'description': 'Papuan/New Guinean/Irian Jayan'},
            {'code': '52126', 'description': 'Paraguayan'},
            {'code': '32118', 'description': 'Penrhyn Islander'},
            {'code': '52127', 'description': 'Peruvian'},
            {'code': '37136', 'description': 'Phoenix Islander'},
            {'code': '37137', 'description': 'Pitcairn Islander'},
            {'code': '124', 'description': 'Polish'},
            {'code': '12932', 'description': 'Portuguese'},
            {'code': '52128', 'description': 'Puerto Rican'},
            {'code': '32119', 'description': 'Pukapuka Islander'},
            {'code': '43115', 'description': 'Punjabi'},
            {'code': '32120', 'description': 'Rakahanga Islander'},
            {'code': '32121', 'description': 'Rarotongan'},
            {'code': '95', 'description': 'Refused to answer'},
            {'code': '12933', 'description': 'Romanian/Rumanian'},
            {'code': '12934', 'description': 'Romany/Gypsy'},
            {'code': '37138', 'description': 'Rotuman/Rotuman Islander'},
            {'code': '12935', 'description': 'Russian'},
            {'code': '31', 'description': 'Samoan'},
            {'code': '37139', 'description': 'Santa Cruz Islander'},
            {'code': '12936', 'description': 'Sardinian'},
            {'code': '12119', 'description': 'Scottish (Scots)'},
            {'code': '12514', 'description': 'Serb/Serbian'},
            {'code': '54115', 'description': 'Seychelles Islander'},
            {'code': '12120', 'description': 'Shetland Islander'},
            {'code': '43116', 'description': 'Sikh'},
            {'code': '42114', 'description': 'Singaporean Chinese'},
            {'code': '44111', 'description': 'Sinhalese'},
            {'code': '12937', 'description': 'Slavic/Slav'},
            {'code': '12938', 'description': 'Slovak'},
            {'code': '12515', 'description': 'Slovene/Slovenian'},
            {'code': '37140', 'description': 'Society Islander (including Tahitian)'},
            {'code': '37141', 'description': 'Solomon Islander'},
            {'code': '53119', 'description': 'Somali'},
            {'code': '12948', 'description': 'South African'},
            {'code': '54116', 'description': 'South American Indian'},
            {'code': '41', 'description': 'South East Asian'},
            {'code': '12500', 'description': 'South Slav (formerly Yugoslav groups) nfd'},
            {'code': '12599', 'description': 'South Slav (formerly Yugoslav) nec'},
            {'code': '410', 'description': 'Southeast Asian nfd'},
            {'code': '12939', 'description': 'Spanish'},
            {'code': '441', 'description': 'Sri Lankan'},
            {'code': '44199', 'description': 'Sri Lankan nec'},
            {'code': '44100', 'description': 'Sri Lankan nfd'},
            {'code': '44112', 'description': 'Sri Lankan Tamil'},
            {'code': '12940', 'description': 'Swedish'},
            {'code': '12941', 'description': 'Swiss'},
            {'code': '51125', 'description': 'Syrian'},
            {'code': '42116', 'description': 'Taiwanese Chinese'},
            {'code': '43114', 'description': 'Tamil'},
            {'code': '41415', 'description': 'Thai/Tai/Siamese'},
            {'code': '44415', 'description': 'Tibetan'},
            {'code': '35', 'description': 'Tokelauan'},
            {'code': '33', 'description': 'Tongan'},
            {'code': '37142', 'description': 'Torres Strait Islander/Thursday Islander'},
            {'code': '37143', 'description': 'Tuamotu Islander'},
            {'code': '51126', 'description': 'Tunisian'},
            {'code': '51127', 'description': 'Turkish (incl Turkish Cypriot)'},
            {'code': '37144', 'description': 'Tuvalu Islander/Ellice Islander'},
            {'code': '53117', 'description': 'Ugandan'},
            {'code': '12942', 'description': 'Ukrainian'},
            {'code': '52129', 'description': 'Uruguayan'},
            {'code': '37145', 'description': 'Vanuatu Islander/New Hebridean'},
            {'code': '52130', 'description': 'Venezuelan'},
            {'code': '413', 'description': 'Vietnamese'},
            {'code': '42115', 'description': 'Vietnamese Chinese'},
            {'code': '37146', 'description': 'Wake Islander'},
            {'code': '37147', 'description': 'Wallis Islander'},
            {'code': '12121', 'description': 'Welsh'},
            {'code': '53118', 'description': 'West Indian/Caribbean'},
            {'code': '37148', 'description': 'Yap Islander'},
            {'code': '51128', 'description': 'Yemeni'},
        ]
    },
    getters: {
        ethnicityCodes: state => {
            return state.ethnicityCodes
        }
    }
}
