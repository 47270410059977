import {
    GET_SMS_MESSAGE,
    SET_SMS_MESSAGE,
    GET_SMS_MESSAGES,
    SET_SMS_MESSAGES,
    SAVE_SMS_MESSAGE,
    GET_DATE_RECIPIENTS,
    SET_DATE_RECIPIENTS,
    CANCEL_SMS_MESSAGE
} from '@/store/actions/sms'

import axios from '@/axios/axios_config'
import swal from 'sweetalert2'
import router from '@/router/router'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

export default {
    state: {
        smsMessage: {},
        smsMessages: [],
        dateRecipients: {},
    },
    getters: {
        smsMessage: state => {
            if (state.smsMessage) {
                return state.smsMessage
            } else if (state.smsMessages.length > 0) {
                return state.smsMessages[0]
            } else {
                return {}
            }
        },
        smsMessages: state => {
            return state.smsMessages
        },
        dateRecipients: state => {
            return state.dateRecipients
        }
    },
    mutations: {
        [SET_SMS_MESSAGE]: (state, payload) => {
            state.smsMessage = payload
        },
        [SET_SMS_MESSAGES]: (state, payload) => {
            var messages = []
            payload.forEach(message => {
                var recipients = message.recipients.split(',').map(item => {
                    return item.trim()
                })
                message.recipients = recipients
            })
            state.smsMessages = payload
        },
        [SET_DATE_RECIPIENTS]: (state, payload) => {
            state.dateRecipients = payload
        }
    },
    actions: {
        [SET_SMS_MESSAGE]: ({commit}, payload) => {
            commit(SET_SMS_MESSAGE, payload)
        },
        [GET_SMS_MESSAGES]: ({commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/sms-message/?limit=999999')
                .then((response) => {
                    commit(SET_SMS_MESSAGES, response.data.items)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [SAVE_SMS_MESSAGE]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/sms-message/add/', payload).then(response => {
                return response.data
            })
        },
        [CANCEL_SMS_MESSAGE]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/sms-message/'+ payload.id +'/remove/', payload).then(response => {
                if (response.data.success) {
                    return true
                } else {
                    return false
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Error',
                    text: error.message,
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: true,
                })
                swal.fire(options)
                throw error.message
            })
        },
        [GET_DATE_RECIPIENTS]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/sms-message/get-recipients/')
            .then((response) => {
                commit(SET_DATE_RECIPIENTS, response.data)
                // response.data.forEach(function(item) {
                //     Object.keys(item.event_list).forEach(function(events) {
                //         commit(SET_CALENDAR_EVENTS, {
                //             year: item.event_list[events].year,
                //             month: item.event_list[events].month,
                //             id: item.id,
                //             data: item.event_list[events].events
                //         })
                //     })
                // })
            })
            .catch((error) => {
                if (error) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
            })
        }
    },
}