 export const sweetalert_options = {
    customClass: {
        container: 'sweetalert-modal',
        popup: 'box-shadow-dark'
    },
    heightAuto: false,
    width: '20em',
    showCloseButton: false,
    showClass: {
      popup: 'swal2-show-mine',
      backdrop: 'swal2-backdrop-show',
      icon: 'swal2-icon-show'
    },
    closeButtonHtml: '<img class="close-cross" src="/static/img/close-cross-purple.svg"></img>'
}