import {
    GET_MY_TODO,
    GET_CARE_LOCATION_TODO,
    GET_MY_COMPLETED_TODO,
    SET_MY_TODO,
    SET_CARE_LOCATION_TODO,
    ADD_CARE_LOCATION_TODO,
    SET_CARE_LOCATION_TODO_TOTAL,
    SET_MY_COMPLETED_TODO,
    EDIT_TODO,
    // EDIT_MY_TODO,
    // EDIT_CARE_LOCATION_TODO,
    EDIT_COMPLETED_TODO,
    DELETE_TODO,
    CLEAN_COMPLETED_TO_DO,
    CREATE_TODO,
    GET_SINGLE_TODO,
} from '@/store/actions/toDo'


import axios from '@/axios/axios_config'
import swal from 'sweetalert2'
// import router from '@/router/router'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'
import { vm } from '../../main'

export default {
    state: {
        myToDo: [],
        careLocationToDo: [],
        careLocationToDoTotal: 0,
        completedToDo: [],
    },
    getters: {
        myToDo: state => {
            return state.myToDo
        },
        careLocationToDo: state => {
            return state.careLocationToDo
        },
        careLocationToDoTotal: state => {
            return state.careLocationToDoTotal
        },
        completedToDo: state => {
            return state.completedToDo
        },
        allToDo: state => {
            var tempList = []
            for (var i = 0; i < state.myToDo.length; i++) {
                tempList.push(JSON.parse(JSON.stringify(state.myToDo[i])))
            }
            for (var i = 0; i < state.completedToDo.length; i++) {
                tempList.push(JSON.parse(JSON.stringify(state.completedToDo[i])))
            }
            for (var i = 0; i < state.careLocationToDo.length; i++) {
                tempList.push(JSON.parse(JSON.stringify(state.careLocationToDo[i])))
            }
            if (!tempList) {
                return []
            }
            return tempList
        }
    },
    mutations: {
        [SET_MY_TODO]: (state, payload) => {
            state.myToDo = payload
        },
        [SET_CARE_LOCATION_TODO]: (state, payload) => {
            state.careLocationToDo = payload
        },
        [SET_MY_COMPLETED_TODO]: (state, payload) => {
            state.completedToDo = payload
        },
        [ADD_CARE_LOCATION_TODO]: (state, payload) => {
            for (var i = 0; i < payload.length; i++) {
                state.careLocationToDo.push(payload[i])
            }
        },
        [SET_CARE_LOCATION_TODO_TOTAL]: (state, payload) => {
            state.careLocationToDoTotal = payload
        }
    },
    actions: {
        [GET_MY_TODO]: ({commit}, payload) => {
            var queryString = '/api/v2/to-do/?limit=99999'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
            .then((response) => {
                if (response.data) {
                    commit(SET_MY_TODO, response.data.items)
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_CARE_LOCATION_TODO]: ({commit}, payload) => {
            var queryString = '/api/v2/to-do/?'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
            .then((response) => {
                if (response.data) {
                    if (payload.additional) {
                        commit(ADD_CARE_LOCATION_TODO, response.data.items)
                        commit(SET_CARE_LOCATION_TODO_TOTAL, response.data.meta.total_count)
                    } else {
                        commit(SET_CARE_LOCATION_TODO, response.data.items)
                        commit(SET_CARE_LOCATION_TODO_TOTAL, response.data.meta.total_count)
                    }
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                var message = error
                if (error.response) {
                    message = error.response.message
                }
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_MY_COMPLETED_TODO]: ({commit}, payload) => {
            var queryString = '/api/v2/to-do/?limit=99999'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
            .then((response) => {
                if (response.data) {
                    commit(SET_MY_COMPLETED_TODO, response.data.items)
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [CREATE_TODO]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/to-do/create/', payload)
            .then((response) => {
                if (response.data) {
                    if (payload.loadNew && payload.list == 'myToDo') {
                        commit(SET_MY_TODO, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'completedToDo') {
                        commit(SET_MY_COMPLETED_TODO, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'careLocationToDo') {
                        commit(SET_CARE_LOCATION_TODO, response.data.items)
                    }
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [EDIT_TODO]: ({getters, commit}, payload) => {

            return axios.post(axios.defaults.baseURL + '/api/v2/to-do/update/' + payload.item.id + '/', payload)
            .then((response) => {
                if (response.data) {
                    if (payload.loadNew && payload.list == 'myToDo') {
                        commit(SET_MY_TODO, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'completedToDo') {
                        commit(SET_MY_COMPLETED_TODO, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'careLocationToDo') {
                        commit(SET_CARE_LOCATION_TODO, response.data.items)
                    }
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_SINGLE_TODO]: ({}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/to-do/' + payload.id + '/')
            .then((response) => {
                if (response.data) {
                    return response.data
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.response.data.message
            })
        },
        [DELETE_TODO]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/to-do/delete/' + payload.item.id + '/', payload)
            .then((response) => {
                if (response.data) {
                    if (payload.loadNew && payload.list == 'myToDo') {
                        commit(SET_MY_TODO, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'completedToDo') {
                        commit(SET_MY_COMPLETED_TODO, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'careLocationToDo') {
                        commit(SET_CARE_LOCATION_TODO, response.data.items)
                    }
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [CLEAN_COMPLETED_TO_DO]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/to-do/clean-completed/', payload)
            .then((response) => {
                if (response.data) {
                    commit(SET_MY_COMPLETED_TODO, response.data.items)
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        }
    }
}