import Vue from 'vue'
import App from './App.vue'
import router from '@/router/router'
import { store } from '@/store/store'
import VueSession from 'vue-session'
import VueSweetalert2 from 'vue-sweetalert2'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'
// import pouch from '@/pouchDB/pouchdb'
import axios from '@/axios/axios_config'
import vueSignature from "vue-signature"
import VueClipboard from 'vue-clipboard2'
import VueAnalytics from 'vue-ua'

import { AUTO_SIGN_IN } from '@/store/actions/auth'
import { GET_HELP } from '@/store/actions/needHelp'
import './quasar'

Vue.use(VueSession)
Vue.use(VueSweetalert2, sweetalert_options)
Vue.use(vueSignature)
Vue.use(VueClipboard)

Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: 'MMPO Business App',
  // [Required] The version of your app.
  appVersion: '1',
  // [Required] Your Google Analytics tracking ID.
  trackingId: 'UA-159309756-1',
  // If you're using vue-router, pass the router instance here.
  vueRouter: router,
  trackPage: true|false,
})

Vue.config.productionTip = false

export const vm = new Vue({
    router,
    store,
    // pouch,

    axios,
    created() {
        this.$store.dispatch(AUTO_SIGN_IN)
        this.$store.dispatch(GET_HELP)
    },
    render: h => h(App),
}).$mount('#app')

