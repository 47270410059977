import {
    GET_CALENDAR_EVENTS,
    SET_CALENDAR_EVENTS,
    ADD_EVENT,
    EDIT_EVENT,
    GET_SINGLE_CALENDAR_EVENT,
    REMOVE_EVENT,
    SET_CALENDAR_MIDWIVES,
    SET_CURRENT_EVENT,
    ADD_SELECTED_MIDWIVES,
    SET_SELECTED_MIDWIVES,
    REMOVE_SELECTED_MIDWIVES,
    SET_MIDWIFE_COLOUR,
    SET_CALENDAR_DATE_RANGE,
    SET_SELECTED_MIDWIVES_IDS,
    SET_CALENDAR_VIEW,
    SEND_MEETING,
    ADD_EVENT_NOW,
} from '@/store/actions/calendar'
import {
    SET_APPOINTMENTS
} from '@/store/actions/user'

import { store } from '@/store/store'
import router from '@/router/router'
import axios from '@/axios/axios_config'
import moment from 'moment'
import swal from 'sweetalert2'
import { vm } from '../../main'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

moment.locale('en-nz')

export default {
    state: {
        calendarView: 'Month', // default
        calendarTypes: [
            'Booking',
            'Antenatal',
            'Postnatal',
            'Education',
            'Unavailable',
            'Practice meeting'
        ],
        // calendarColours: {
        //     Booking: 'cal-teal',
        //     Antenatal: 'cal-blue',
        //     Postnatal: 'cal-pink',
        //     Education: 'cal-green',
        //     Unavailable: 'cal-grey',
        //     Practicemeeting: 'cal-yellow',
        // },
        calendarColours: [
            '#ffa295','#ffea57','#7ee7ff','#c6ff00',
            '#dac5ff','#c2e3ff','#ff9260','#ffafff',
            '#f8f900','#fdeee7','#eafa83','#d1d5ff',
            '#ffccbe','#ffccab','#d8fbc0','#e6e0ec',
            '#ffb37c','#a4f874','#ffa03c','#00ff00',
            '#ffcee3','#f9dfbd','#4bff95','#ff95ae',
            '#ffc06a','#c5fde6','#ffc3c3','#ffc035',
            '#00ffcc','#ffecad','#7effff',
        ],
        calendarTextColour: 'rgba(0, 0, 0, 0.6)',
        calendarCurrentEvent: null,
        calendarEvents: [],
        calendarMidwives: [],
        selectedMidwives: [],
        selectedMidwivesIds: [],
        calendarDateRange: [],
        calendarViewOptions: {
            "Day": "Day", 
            "3 Day": "3 Day", 
            "Week": "Week", 
            "Month": "Month"
        },
        calendarAppointmentOptions: [
            {
                hourDuration: .25,
                eventDuration: {
                    string: '15 min', 
                    value: '0:15:00'
                }
            },
            {
                hourDuration: .5,
                eventDuration: {
                    string: '30 min', 
                    value: '00:30:00'
                }
            },
            {
                hourDuration: .75,
                eventDuration: {
                    string: '45 min', 
                    value: '00:45:00'
                }
            },
            {
                hourDuration: 1,
                eventDuration: {
                    string: '1 hour', 
                    value: '01:00:00'
                }
            },
            {
                hourDuration: 1.25,
                eventDuration: {
                    string: '1 hour 15 min', 
                    value: '01:15:00'
                }
            },
            {
                hourDuration: 1.5,
                eventDuration: {
                    string: '1 hour 30 min', 
                    value: '01:30:00'
                }
            },
            {
                hourDuration: 1.75,
                eventDuration: {
                    string: '1 hour 45 min', 
                    value: '01:45:00'
                }
            },
            {
                hourDuration: 2,
                eventDuration: {
                    string: '2 hours', 
                    value: '02:00:00'
                }
            },
        ]
    },
    getters: {
        calendarView: state => {
            return state.calendarView
        },
        calendarTypes: state => {
            return state.calendarTypes
        },
        calendarColours: state => {
            return state.calendarColours
        },
        calendarCurrentEvent: state => {
            return state.calendarCurrentEvent
        },
        calendarEvents: state => {
            return state.calendarEvents
        }, 
        dateRange: state => {
            return state.dateRange
        },
        calendarMidwives: state => {
            return state.calendarMidwives
        },
        selectedMidwives: state => {
            return state.selectedMidwives
        },
        calendarDateRange: state => {
            return state.calendarDateRange
        },
        selectedMidwivesIds: state => {
            return state.selectedMidwivesIds
        },
        flatCalendarEvents: state => {
            var tmp_list = []
            var eventIds = {}
            state.calendarMidwives.forEach(function(item) {
                Object.keys(item.events).forEach(function(year) {
                    Object.keys(item.events[year]).forEach(function(month) {
                        Object.keys(item.events[year][month]).forEach(function(event) {
                            if (!eventIds[item.events[year][month][event].id]) {
                                tmp_list.push(item.events[year][month][event])
                                eventIds[item.events[year][month][event].id] = true
                            }
                        })
                    })
                })
            })
            return tmp_list
        },
        processedCalendarEvents: state => {
            var tmp_list = []
            var eventIds = {}
            var count = 1
            state.calendarMidwives.forEach(function(item) {
                Object.keys(item.events).forEach(function(year) {
                    Object.keys(item.events[year]).forEach(function(month) {
                        Object.keys(item.events[year][month]).forEach(function(event) {
                            if (!eventIds[item.events[year][month][event].id]) {
                                var eventItem = item.events[year][month][event]
                                // eventItem.color = 'cal-' + count
                                // eventItem.textColor = 'cal-' + count
                                eventItem.color = state.calendarColours[count-1]
                                eventItem.textColor = state.calendarTextColour
                                tmp_list.push(eventItem)
                                eventIds[eventItem.id] = true
                            }
                        })
                    })
                })
                count++
                var checkCount = count%32 
                if (checkCount == 0) {
                    count = 1
                }
            })
            tmp_list.forEach(function(event) {
                // need to make the 'start' object
                // need to make the 'end' object
                event.start = {timeZone: 'Pacific/Auckland'}
                event.end = {timeZone: 'Pacific/Auckland'}

                if (event.all_day) {
                    event.start.date = event.start_date
                    event.end.date = event.end_date
                } else if (!event.start_time && !event.end_time) {
                    // if either start_time or end_time are null
                    // then calendar won't work and must be an
                    // 'all_day' event
                    event.start.date = event.start_date
                    event.end.date = event.end_date
                } else {
                    // here it is assumed it is NOT an all day event
                    // and the start and end times are provided
                    event.start.dateTime = event.start_date + 'T' + event.start_time
                    event.end.dateTime = event.end_date + 'T' + event.end_time
                }

                // need to add colours depending on type of event
                // THESE WILL NOT BE THE DISPLAYED COLOURS NECESSARILY 
                // DIFFERENT COLOURS IF MORE THAN ONE PERSON'S EVENTS DISPLAYED
                if (state.selectedMidwives.length == 1 && state.selectedMidwives[0].id == store.state.user.user.id) {
                    var appointmentType = store.getters.appointmentTypes.find(x => x.name == event.type_of_appointment.name)
                    event.textColor = appointmentType.colour
                    event.color = appointmentType.backgroundColour
                } else if (state.selectedMidwives.length == 1 && state.selectedMidwives[0].id != store.state.user.user.id) {
                    var appointmentType = store.getters.appointmentTypes.find(x => x.name == event.type_of_appointment.name)
                    event.textColor = appointmentType.colour
                    event.color = appointmentType.backgroundColour
                }
            })
            return tmp_list
        },
        calendarViewOptions: state => {
            return state.calendarViewOptions
        },
        calendarAppointmentOptions: state => {
            return state.calendarAppointmentOptions
        }
    },
    mutations: {
        // [SET_CALENDAR_EVENTS]: (state, payload) => {
        //     state.calendarEvents = payload
        // },
        [EDIT_EVENT]: (state, payload) => {
            var i = 0
            do {
                if (state.calendarEvents[i].id == payload._id) {
                    state.calendarEvents[i] == payload
                    i = state.calendarEvents.length
                }
                i++
            } while (i < state.calendarEvents.length)
        },
        [REMOVE_EVENT]: (state, payload) => {
            var i = 0
            do {
                if (state.calendarEvents[i].id == payload._id) {
                    state.calendarEvents.splice(i, 1)
                    i = state.calendarEvents.length
                }
                i++
            } while (i < state.calendarEvents.length)
        },
        [SET_CALENDAR_MIDWIVES]: (state, payload) => {
            vm.$set(state, 'calendarMidwives', payload)
        },
        [SET_CALENDAR_EVENTS]: (state, payload) => {
            // replace the dict of events for midwife per month
            var i = 0
            var found = false
            do {
                if (state.calendarMidwives[i].id == payload.id) {
                    if (!state.calendarMidwives[i]['events'][payload.year]) {
                        vm.$set(state.calendarMidwives[i]['events'], payload.year, {})
                    }
                    vm.$set(state.calendarMidwives[i]['events'][payload.year], payload.month, payload.data)
                    i = state.calendarMidwives.length
                } else {
                    i++
                }
            } while (i < state.calendarMidwives.length)
        },
        [SET_CURRENT_EVENT]: (state, payload) => {
            state.calendarCurrentEvent = payload
        },
        [ADD_SELECTED_MIDWIVES]: (state, payload) => {
            state.selectedMidwives.push(payload)
        },
        [SET_SELECTED_MIDWIVES]: (state, payload) => {
            state.selectedMidwives = payload
        },
        [REMOVE_SELECTED_MIDWIVES]: (state, payload) => {
            state.selectedMidwives.splice(payload.index, 1)
        },
        [SET_MIDWIFE_COLOUR]: (state, payload) => {
            if (state.calendarMidwives[payload.num]) {
                state.calendarMidwives[payload.num]['colour'] = payload.colour
            }
        },
        [SET_CALENDAR_DATE_RANGE]: (state, payload) => {
            state.calendarDateRange = payload
        },
        [SET_SELECTED_MIDWIVES_IDS]: (state, payload) => {
            state.selectedMidwivesIds = payload
        },
        [SET_CALENDAR_VIEW]: (state, payload) => {
            state.calendarView = payload
        }
    },
    actions: {
        [SET_CALENDAR_MIDWIVES]: ({commit}) => {
            var midwifeList = []
            var user = store.getters.user
            if (user.careLocationMidwives.length > 0) {
                var count = 1
                user.careLocationMidwives.forEach(function(item) {
                    midwifeList.push({
                        id: item.id,
                        name: item.name,
                        startDate: null,
                        endDate: null,
                        events: {},
                        colour: 'cal-' + count
                    })
                    count++
                })
            }
            commit(SET_CALENDAR_MIDWIVES, midwifeList)
        },
        [GET_CALENDAR_EVENTS]: ({state, commit}, payload) => {
            var queryString = ''
            if ('query' in payload) {
                queryString = payload.query
            }
            return axios.post(axios.defaults.baseURL + '/api/v2/calendar-event/get/' + queryString, payload)
            .then((response) => {
                response.data.forEach(function(item) {
                    Object.keys(item.event_list).forEach(function(events) {
                        commit(SET_CALENDAR_EVENTS, {
                            year: item.event_list[events].year,
                            month: item.event_list[events].month,
                            id: item.id,
                            data: item.event_list[events].events
                        })
                    })
                })
            })
            .catch((error) => {
                if (error) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
            })
        },
        [ADD_EVENT]: ({commit, dispatch}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/calendar-event/add/', payload)
            .then((response) => {
                response.data.forEach(function(item) {
                    Object.keys(item.event_list).forEach(function(events) {
                        commit(SET_CALENDAR_EVENTS, {
                            year: item.event_list[events].year,
                            month: item.event_list[events].month,
                            id: item.id,
                            data: item.event_list[events].events
                        })
                    })
                })
            })
            .catch((error) => {
                if (error) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire()
                }
            })
        },
        [EDIT_EVENT]: ({commit, dispatch}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/calendar-event/' + payload.eventData.id + '/edit/', payload)
            .then((response) => {
                response.data.forEach(function(item) {
                    Object.keys(item.event_list).forEach(function(events) {
                        commit(SET_CALENDAR_EVENTS, {
                            year: item.event_list[events].year,
                            month: item.event_list[events].month,
                            id: item.id,
                            data: item.event_list[events].events
                        })
                    })
                })
            })
            .catch((error) => {
                if (error) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
            })
        },
        [GET_SINGLE_CALENDAR_EVENT]: ({commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/calendar-event/' + payload.id + '/get/')
            .then((response) => {
                if (response.data) {
                    commit(SET_CURRENT_EVENT, response.data)
                }
            })
            .catch((error) => {
                if (error) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
            })
        },
        [REMOVE_EVENT]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/calendar-event/' + payload.eventData.id + '/remove/', payload)
            .then((response) => {
                if (payload.appointments) {
                    commit(SET_APPOINTMENTS, response.data)
                } else {
                    response.data.forEach(function(item) {
                        Object.keys(item.event_list).forEach(function(events) {
                            commit(SET_CALENDAR_EVENTS, {
                                year: item.event_list[events].year,
                                month: item.event_list[events].month,
                                id: item.id,
                                data: item.event_list[events].events
                            })
                        })
                    })
                }
            })
            .catch((error) => {
                if (error) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
            })
        },
        [SET_CURRENT_EVENT]: ({commit}, payload) => {
            commit(SET_CURRENT_EVENT, payload)
        },
        [ADD_SELECTED_MIDWIVES]: ({commit}, payload) => {
            commit(ADD_SELECTED_MIDWIVES, payload)
        },
        [SET_SELECTED_MIDWIVES]: ({commit}, payload) => {
            commit(SET_SELECTED_MIDWIVES, payload)
        },
        [REMOVE_SELECTED_MIDWIVES]: ({commit}, payload) => {
            commit(REMOVE_SELECTED_MIDWIVES, payload)
        },
        [SET_CALENDAR_DATE_RANGE]: ({commit}, payload) => {
            commit(SET_CALENDAR_DATE_RANGE, payload)
        },
        [SET_SELECTED_MIDWIVES_IDS]: ({commit}, payload) => {
            commit(SET_SELECTED_MIDWIVES_IDS, payload)
        },
        [SET_CALENDAR_VIEW]: ({commit}, payload) => {
            commit(SET_CALENDAR_VIEW, payload)
        },
        [SEND_MEETING]: ({}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/calendar-event/' + payload.eventData.id + '/send-meeting/', payload)
            .then((response) => {
                
            })
            .catch((error) => {
                if (error) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
            })
        },
        [ADD_EVENT_NOW]: ({}, payload) => {
            store.dispatch(SET_CURRENT_EVENT, false)
            var m = moment().year(payload.date.c.year).month(payload.date.c.month-1).date(payload.date.c.day).hour(0).minute(0).second(0).millisecond(0).add(payload.hour, 'hours')
            store.dispatch('setPageTransition', 'slideFade')
            store.dispatch('setHomeTransition', 'popStay')
            router.push({name: 'CalendarAddEvent', query: {timestamp: m.valueOf()}})
        }
    },
}
