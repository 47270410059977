import {
    LOGIN, 
    LOGOUT, 
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SET_NEW_PASSWORD, 
    AUTO_SIGN_IN, 
    SET_USER, 
    OBTAIN_TOKEN, 
    REFRESH_TOKEN, 
    REMOVE_TOKEN, 
    INSPECT_TOKEN,
    UPDATE_TOKEN,
    IS_TEST_GROUP,
} from '@/store/actions/auth'
import {
    GET_USER,
    RESET_USER,
} from '@/store/actions/user'
import axios from '@/axios/axios_config'
import jwt_decode from 'jwt-decode'

import swal from 'sweetalert2'
import router from '@/router/router'
import { store } from '@/store/store'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

export default {
    state: {
        jwt: JSON.parse(localStorage.getItem('noket')),
        endpoints: {
            obtainJWT: axios.defaults.baseURL + '/auth/jwt/create/',
            refreshJWT: axios.defaults.baseURL + '/auth/jwt/refresh/'
        }
    },
    getters: {
        jwt: state => {
            return state.jwt
        },
        endpoints: state => {
            return state.endpoints
        }
    },
    mutations: {
        [SET_USER]: (state, payload) => {
            state.user = payload
        },
        [UPDATE_TOKEN]: (state, payload) => {
            localStorage.setItem('noket', JSON.stringify(payload))
            state.jwt = payload
        },
        [REMOVE_TOKEN]: (state) => {
            localStorage.removeItem('noket')
            state.jwt = null
        }
    },
    actions: {
        [LOGIN]: ({state, commit, dispatch}, payload) => {
            commit(REMOVE_TOKEN, payload)
            return dispatch(OBTAIN_TOKEN, payload).then((response) => {
                dispatch(GET_USER)
            })
            .then((response) => {
                router.push('/')
            })
        },
        [LOGOUT]: ({commit, dispatch}, payload) => {
            commit(REMOVE_TOKEN, payload)
            dispatch(RESET_USER)
            router.push({'name': 'Login'})
        },
        [FORGOT_PASSWORD]: ({}, payload) => {
            return axios.post(axios.defaults.baseURL + '/auth/users/reset_password/', payload)
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [RESET_PASSWORD]: (context, payload) => {
            return axios.post(axios.defaults.baseURL + '/auth/users/reset_password_confirm/', payload)
                .then((response) => {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Great',
                        text: 'Your password has been changed. Please try to login again.',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal success',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-success.svg',
                        showConfirmButton: false,
                        showCancelButton: false,
                        // showCloseButton: true,
                        timer: 2000,
                    })
                    swal.fire(options)
                    return true
                })
                .catch((error) => {
                    console.log(error)
                    const error_list = []
                    if (error.response.data.non_field_errors) {
                        error_list.push(error.response.data.non_field_errors)
                    }
                    if (error.response.data.new_password) {
                        error_list.push(error.response.data.new_password)
                    }
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops we have some errors',
                        text: error_list,
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                    return false
                })
        },
        [SET_NEW_PASSWORD]: ({commit}, payload) => {
        },
        [AUTO_SIGN_IN]: ({dispatch}) => {
            dispatch(INSPECT_TOKEN).then((response) => {
                if (response) {
                    dispatch(GET_USER)
                }
            })
        },
        [OBTAIN_TOKEN]: ({state, commit}, payload) => {
            return axios.post(state.endpoints.obtainJWT, payload)
            .then((response) => {
                commit(UPDATE_TOKEN, response.data)
                return ''
            })
            .catch((error) => {
                const error_list = []
                console.log(error)
                if (error.response.data.non_field_errors) {
                    error_list.push(error.response.data.non_field_errors)
                }
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error_list,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [REFRESH_TOKEN]: ({state, commit}) => {
            const payload = {
                refresh: state.jwt.refresh
            }
            axios.post(state.endpoints.refreshJWT, payload)
            .then((response) => {
                if (response.data.code == 'token_not_valid') {
                    throw error
                } else {
                    commit(UPDATE_TOKEN, response.data)
                }
            })
            .catch((error) => {
                // var message = error.response.data.detail
                // if (error.response.data.code == 'token_not_valid') {
                //     message = 'Your session has expired.'
                // }
                // swal.fire({
                //     title: 'Whoops',
                //     icon: 'warning',
                //     text: message
                // })
                router.push({name: 'Login'})
            })
        },
        [INSPECT_TOKEN]: ({state, dispatch}) => {
            var token = false
            if (state.jwt) {
                token = state.jwt.access
            }

            if (token) {
                const decoded = jwt_decode(token)
                const exp = decoded.exp
                const orig_iat = decoded.orig_iat
                if (exp - (Date.now()/1000) >= 1800) {
                    // still has more than 30mins remains
                    return true
                } else if (exp - (Date.now()/1000) < 1800) { // && (Date.now()/1000) - orig_iat < 628200) {
                    return dispatch(REFRESH_TOKEN)
                    .then((response) => {
                        return true
                    }).catch((response) => {
                        return false
                    })
                } else {
                    return false
                }
            }
            return false
        },
        [IS_TEST_GROUP]: ({state}, payload) => {
            if (!payload) {
                for (var i = Object.keys(store.state.user.user.isTest).length - 1; i >= 0; i--) {
                    if (Object.keys(store.state.user.user.isTest)[i]) {
                        return true
                    }
                }
            }
            if (store.state.user.user.isTest != {}) {

                if (payload == 'quick_note' && store.state.user.user.isTest.can_quick_note) {
                    return true
                }
                if (payload == 'to_do' && store.state.user.user.isTest.can_to_do) {
                    return true
                }
            }
            return false
        }
    }
}