import {
    GET_CONNECT,
    GET_ALL_CONNECT,
    SET_CONNECT,
    SET_ALL_CONNECT,
    UPDATE_CONNECT,
    REMOVE_CONNECT,
    GET_FAVOURITE,
    SET_FAVOURITE,
    ADD_FAVOURITE,
    REMOVE_FAVOURITE,
    SAVE_CONNECT,
    RETURN_CONNECTION,
    SET_ARCHIVED_CONNECTION,
    ADD_ARCHIVED_CONNECTION,
    REMOVE_ARCHIVED_CONNECTION,
    AUTO_UPDATE_CONNECT,
    GET_CONNECT_NOTIFICATIONS,
    SET_CONNECT_NOTIFICATIONS,
    ACKNOWLEDGE_NOTIFICATION,
    GET_CONNECT_REFERRALS,
    SET_CONNECT_REFERRALS
} from '@/store/actions/connect'

import axios from '@/axios/axios_config'
import swal from 'sweetalert2'
import router from '@/router/router'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

export default {
    state: {
        // need a way to link connects with care locations and just be the current midwife
        connections: [],
        favourites: [],
        archivedConnections: [],
        connectNotifications: [],
        connectReferrals: []
    },
    getters: {
        connections: state => {
            return state.connections
        },
        favourites: state => {
            return state.favourites
        },
        archivedConnections: state => {
            return state.archivedConnections
        },
        connectNotifications: state => {
            return state.connectNotifications
        },
        connectReferrals: state => {
            return state.connectReferrals
        }
    },
    mutations: {
        [SET_CONNECT]: (state, payload) => {
            state.connections = payload
        },
        [SET_FAVOURITE]: (state, payload) => {
            state.favourites = payload
        },
        [SET_ARCHIVED_CONNECTION]: (state, payload) => {
            state.archivedConnections = payload
        },
        [REMOVE_CONNECT]: (state, payload) => {
            var i = 0
            do {
                if (state.connections[i].contact.id == payload) {
                    state.connections.splice(i, 1)
                    i = state.connections.length
                }
                i++
            } while (i < state.connections.length)
        },
        [UPDATE_CONNECT]: (state, payload) => {
            var i = 0
            if (payload.enabled) {
                do {
                    if (state.connections[i].contact.id == payload.id) {
                        state.connections[i].contact = payload
                        i = state.connections.length
                    }
                    i++
                } while (i < state.connections.length)
            } else { 
                do {
                    if (state.archivedConnections[i].contact.id == payload.id) {
                        state.archivedConnections[i].contact = payload
                        i = state.archivedConnections.length
                    }
                    i++
                } while (i < state.archivedConnections.length)
            }
        },
        [SET_CONNECT_NOTIFICATIONS]: (state, payload) => {
            state.connectNotifications = payload
        },
        [SET_CONNECT_REFERRALS]: (state, payload) => {
            state.connectReferrals = payload
        }
    },
    actions: {
        [GET_CONNECT]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/contact-woman-related/?limit=999999')
            .then((response) => {
                if (response.data.items) {
                    // commit(SET_CONNECT, response.data.items)
                    var connectionsList = []
                    var archiveconnectionsList = []
                    response.data.items.filter(function(item) {
                        if (item.contact.enabled) {
                            connectionsList.push(item)
                        } else {
                            archiveconnectionsList.push(item)
                        }
                    })
                    commit(SET_CONNECT, connectionsList)
                    commit(SET_ARCHIVED_CONNECTION, archiveconnectionsList)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_FAVOURITE]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/contact-woman-favourite-related/')
            .then((response) => {
                if (response.data) {
                    commit(SET_FAVOURITE, response.data)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [ADD_FAVOURITE]: ({state, commit}, payload) => {
            var temp_update = state.favourites
            temp_update.push(payload)
            commit(SET_FAVOURITE, temp_update)
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman-favourite-related/' + payload + '/add/')
            .then((response) => {
                if (response.data) {
                    commit(SET_FAVOURITE, response.data)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [REMOVE_FAVOURITE]: ({state, commit}, payload) => {
            var temp_update = []
            for (var i = 0; i < state.favourites.length; i++) {
                if (state.favourites[i] != payload) {
                    temp_update.push(state.favourites[i])
                }
            }
            commit(SET_FAVOURITE, temp_update)
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman-favourite-related/' + payload + '/remove/')
            .then((response) => {
                if (response.data) {
                    commit(SET_FAVOURITE, response.data)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [REMOVE_CONNECT]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman/' + payload + '/delete/')
            .then((response) => {
                if (response.data) {
                    commit(REMOVE_CONNECT, payload)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Success',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal success',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-success.svg',
                        text: 'Contact deleted',
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        timer: 2000,
                    })
                    swal.fire(options)
                }
            })
            .catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.message
            })
        },
        [SAVE_CONNECT]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman/new-contact/', payload)
            .then((response) => {
                if (response.data) {
                    commit(SET_CONNECT, response.data.items)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Success',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Contact saved',
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        timer: 2000,
                    })
                    swal.fire(options)
                }
            })
            .catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.message
            })
        },
        [UPDATE_CONNECT]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman/' + payload.id + '/update/', payload)
            .then((response) => {
                if (response.data) {
                    commit(UPDATE_CONNECT, payload)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Success',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal success',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-success.svg',
                        text: 'Contact saved',
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        timer: 2000,
                    })
                    swal.fire(options)
                }
                return true
            })
            .catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.message
            })
        },
        [RETURN_CONNECTION]: ({state}, payload) => {
            var i = 0
            var item = {}
            do {
                if (state.connections[i].id == payload) {
                    item = state.connections[i]
                    i = state.connections.length
                }
                i++
            } while (i < state.connections.length)
            return item
        },
        [ADD_ARCHIVED_CONNECTION]: ({state, commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman/' + payload.id + '/archive/', payload)
            .then((response) => {
                if (response.data.items) {
                    var connectionsList = []
                    var archiveconnectionsList = []
                    response.data.items.filter(function(item) {
                        if (item.contact.enabled) {
                            connectionsList.push(item)
                        } else {
                            archiveconnectionsList.push(item)
                        }
                    })
                    commit(SET_CONNECT, connectionsList)
                    commit(SET_ARCHIVED_CONNECTION, archiveconnectionsList)
                    var temp_update = []
                    for (var i = 0; i < state.favourites.length; i++) {
                        if (state.favourites[i] != payload.id) {
                            temp_update.push(state.favourites[i])
                        }
                    }
                    commit(SET_FAVOURITE, temp_update)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Success',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal success',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-success.svg',
                        text: 'Contact archived',
                        showConfirmButton: false,
                        showCancelButton: false,
                        // showCloseButton: true,
                    })
                    swal.fire(options)
                    router.replace({name: "ViewArchiveConnect", params: {id: payload.id}})
                }
                return true
            })
            .catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.message
            })
        },
        [REMOVE_ARCHIVED_CONNECTION]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman/' + payload.id + '/unarchive/', payload)
            .then((response) => {
                if (response.data.items) {
                    var connectionsList = []
                    var archiveconnectionsList = []
                    response.data.items.filter(function(item) {
                        if (item.contact.enabled) {
                            connectionsList.push(item)
                        } else {
                            archiveconnectionsList.push(item)
                        }
                    })
                    commit(SET_CONNECT, connectionsList)
                    commit(SET_ARCHIVED_CONNECTION, archiveconnectionsList)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Success',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal success',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-success.svg',
                        text: 'Contact unarchived',
                        showConfirmButton: false,
                        showCancelButton: false,
                        // showCloseButton: true,
                        timer: 2000,
                    })
                    swal.fire(options)
                    router.replace({name: "ViewConnect", params: {id: payload.id}})
                }
                return true
            })
            .catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.message
            })
        },
        [AUTO_UPDATE_CONNECT]: ({commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/contact-woman/auto-update/?limit=999999')
            .then((response) => {
                if (response.data.items) {
                    // commit(SET_CONNECT, response.data.items)
                    var connectionsList = []
                    var archiveconnectionsList = []
                    response.data.items.filter(function(item) {
                        if (item.contact.enabled) {
                            connectionsList.push(item)
                        } else {
                            archiveconnectionsList.push(item)
                        }
                    })
                    commit(SET_CONNECT, connectionsList)
                    commit(SET_ARCHIVED_CONNECTION, archiveconnectionsList)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_CONNECT_NOTIFICATIONS]: ({commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/contact-woman-notifications/?limit=999999')
            .then((response) => {
                if (response.data.items) {
                    commit(SET_CONNECT_NOTIFICATIONS, response.data.items)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [ACKNOWLEDGE_NOTIFICATION]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/contact-woman-notifications/acknowledge/', payload)
            .then((response) => {
                if (response.data.items) {
                    commit(SET_CONNECT_NOTIFICATIONS, response.data.items)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_CONNECT_REFERRALS]: ({commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/referral-record-contact/' + payload + '?fields=*&limit=999999')
            .then((response) => {
                if (response.data.items) {
                    commit(SET_CONNECT_REFERRALS, response.data.items)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        }
    }
}