import axios from 'axios'
import {store} from '@/store/store'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.baseURL = process.env.VUE_APP_BASEURL 

axios.interceptors.request.use(request => {
    var token = localStorage.getItem('noket')
    if (token) {
        token = JSON.parse(token).access
    } else if (store.getters.jwt) {
        token = store.getters.jwt.access
    }
    if (token) {
        request.headers.common['Authorization'] = 'JWT ' + token
    }
    return request
})

export default axios