export const GET_REFERRALS = "GET_REFERRALS"
export const GET_SINGLE_REFERRAL = "GET_SINGLE_REFERRAL"
export const SET_SINGLE_REFERRAL = "SET_SINGLE_REFERRAL"
export const GET_REFERRALS_DRAFTS = "GET_REFERRALS_DRAFTS"
export const SET_REFERRALS = "SET_REFERRALS"
export const SET_ERROR_REFERRALS = "SET_ERROR_REFERRALS"
export const EDIT_REFERRAL = "EDIT_REFERRAL"
export const DELETE_REFERRAL_DRAFT = "DELETE_REFERRAL_DRAFT"
export const APPEND_REFERRALS = "APPEND_REFERRALS"
export const SET_REFERRALS_TOTAL = "SET_REFERRALS_TOTAL"
export const SET_REFERRALS_DRAFTS = "SET_REFERRALS_DRAFTS"
export const SET_REFERRALS_DRAFTS_TOTAL = "SET_REFERRALS_DRAFTS_TOTAL"
export const CREATE_REFERRAL = "CREATE_REFERRAL"
export const CREATE_REFERRAL_SIMPLE = "CREATE_REFERRAL_SIMPLE"
export const SET_NUMBER_REQUIRED = 'SET_NUMBER_REQUIRED'
export const SET_NUMBER_REMAINING = 'SET_NUMBER_REMAINING'
export const SHOW_NUMBER_REQUIRED = 'SHOW_NUMBER_REQUIRED'
export const REQUIRED_FIELD_JUMP_LIST = 'REQUIRED_FIELD_JUMP_LIST'
export const SET_JUMP_LIST_NUMBER = 'SET_JUMP_LIST_NUMBER'
export const SET_REFERRAL_SECTIONS_OPEN = 'SET_REFERRAL_SECTIONS_OPEN'
export const RESET_REQUIRED = 'RESET_REQUIRED'
export const SET_ATTEMPTED_SUBMIT = 'SET_ATTEMPTED_SUBMIT'
export const UPDATE_REFERRAL_DATA = 'UPDATE_REFERRAL_DATA'
export const SET_REFERRAL_DATA = 'SET_REFERRAL_DATA'
export const SET_REFERRAL_SELECTED_DATA = 'SET_REFERRAL_SELECTED_DATA'
export const GET_REFERRAL_DATA = 'GET_REFERRAL_DATA'
export const RESET_REFERRAL_DATA = 'RESET_REFERRAL_DATA'
export const SEND_REFERRAL = 'SEND_REFERRAL'
export const RESEND_REFERRAL = 'RESEND_REFERRAL'
export const SET_IS_EDIT = 'SET_IS_EDIT'
export const SET_SEND_TO_LIST = 'SET_SEND_TO_LIST'
export const ADD_SEND_TO_LIST = 'ADD_SEND_TO_LIST'
export const REMOVE_SEND_TO_LIST = 'REMOVE_SEND_TO_LIST'
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT'
export const SET_STEP_NUMBER = 'SET_STEP_NUMBER'
export const MARK_REFERRAL_COMPLETED = 'MARK_REFERRAL_COMPLETED'
export const GET_REFERRAL_ERROR_COUNT = 'GET_REFERRAL_ERROR_COUNT'
export const SET_REFERRAL_ERROR_COUNT = 'SET_REFERRAL_ERROR_COUNT'
export const UPDATE_SELECTED_PREVIOUS_PREGNANCIES = 'UPDATE_SELECTED_PREVIOUS_PREGNANCIES'
export const UPDATE_SELECTED_PREVIOUS_PREGNANCIES_LIST = 'UPDATE_SELECTED_PREVIOUS_PREGNANCIES_LIST'
export const ASSIGN_REFERRAL = 'ASSIGN_REFERRAL'