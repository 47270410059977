import { UPDATE_USER } from '@/store/actions/user'
import { mapGetters } from 'vuex'

var mixins = {
    computed: {
    ...mapGetters([
        'profileFields',
        'user',
    ])
    },
    methods: {
        change(userAttr, apiAttrName, label, inputType, required, maxLength) {
            var myInputAttributes = {
                maxlength: maxLength,
            }
            var body = '<label for="swal-name" class="label -medium -purple -meta-mini">'+ label +'</label>'
            if (userAttr == 'password') {
                myInputAttributes['autocapitalize'] = 'off'
                myInputAttributes['autocorrect'] = 'off'
                body += '<div class="-meta-mini">Note: Password needs to be at least 8 characters long and cannot be all numerals.</div>'
                
            }
            this.$swal({
                title: label,
                input: inputType,
                inputValue: this.user[userAttr],
                showCancelButton: false,
                showCloseButton: true,
                confirmButtonText: 'Save',
                customClass: {
                    popup: 'box-shadow-dark',
                    container: 'sweetalert-modal -padding-small',
                    // header: 'sweet-header-small-left -medium',
                    title: '-left',
                    actions: 'sweet-actions -no-pad -meta-mini -left',
                    content: 'sweet-content-left'
                },
                inputAttributes: myInputAttributes,
                html: body,
                preConfirm: (value) => {
                    return new Promise((resolve) => {
                        if (!value && required) {
                            this.$swal.showValidationMessage('You need to enter a value')
                        }
                        if (userAttr == 'password') {
                            if (!this.checkNumerical(value)) {
                                this.$swal.showValidationMessage('Passwords cannot be all numerals')
                            }
                            if (!this.checkPasswordLength(value)) {
                                this.$swal.showValidationMessage('Passwords must be at least 8 characters long')
                            }
                        }
                        resolve()
                    })
                }
            }).then((response) => {
                if (response.value) {
                    var payload = {
                        field: userAttr, 
                        value: response.value,
                        apiField: apiAttrName,
                    }
                    if (this.profileFields.indexOf(userAttr) >= 0) {
                        payload.profile = true
                    }
                    if (userAttr == 'password') {
                        payload.password = true
                    }
                    this.$store.dispatch(UPDATE_USER, payload)
                    this.$swal({
                        title: 'Success',
                        text: label + ' updated',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal success',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-success.svg',
                        imageWidth: 52,
                        imageHeight: 52,
                        showConfirmButton: false,
                        showCancelButton: false,
                        // showCloseButton: true,
                        timer: 2000,
                    })
                }
            })
        },
        changeSelect(userAttr, apiAttrName, label, options, required) {
            this.$swal({
                title: label,
                input: 'select',
                inputValue: options.inputValue,
                inputOptions: options.inputOptions,
                showCancelButton: false,
                showCloseButton: true,
                confirmButtonText: 'Save',
                customClass: {
                    popup: 'box-shadow-dark',
                    container: 'sweetalert-modal -padding-small',
                    // header: 'sweet-header-small-left -medium',
                    title: '-left',
                    actions: 'sweet-actions -no-pad -meta-mini -left',
                    content: 'sweet-content-left'
                },
                html: '<label for="swal-name" class="label -medium -purple -meta-mini">'+ label +'</label>',
                preConfirm: (value) => {
                    return new Promise((resolve) => {
                        if (!value && required) {
                            this.$swal.showValidationMessage('You need to choose an option')
                        }
                        resolve()
                    })
                }
            }).then((response) => {
                if (response.value) {
                    var payload = {
                        field: userAttr, 
                        value: response.value,
                        apiField: apiAttrName,
                    }
                    if (this.profileFields.indexOf(userAttr) >= 0) {
                        payload.profile = true
                    }
                    this.$store.dispatch(UPDATE_USER, payload)
                    this.$swal({
                        title: 'Success',
                        text: label + ' updated',
                        // icon: 'success',
                        customClass: {
                            container: 'sweetalert-modal success',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-success.svg',
                        imageWidth: 52,
                        imageHeight: 52,
                        showConfirmButton: false,
                        showCancelButton: false,
                        // showCloseButton: true,
                        timer: 2000,
                    })
                }
            })
        },
        isTestUser(permission) {
            if (this.user.isTest != {} && this.user.isTest[permission]) {
                return true
            } else {
                return false
            }
        },
    }
}

export default mixins