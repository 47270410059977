import {
    GET_USER, 
    SET_USER, 
    RESET_USER,
    SET_USER_FIELD, 
    UPDATE_USER,
    UPDATE_USER_IMAGE,
    UPDATE_REFERRAL_CONTACT,
    DELETE_REFERRAL_CONTACT,
    ADD_REFERRAL_CONTACT,
    SET_APPOINTMENTS,
    GET_APPOINTMENTS,
    SET_RECENTLY_VIEWED_CONNECT,
    ADD_ADDRESS,
    UPDATE_ADDRESS,
    REMOVE_ADDRESS,
    ADD_SMS_TEMPLATE,
    UPDATE_SMS_TEMPLATE,
    REMOVE_SMS_TEMPLATE
} from '@/store/actions/user'
import {
    GET_CALENDAR_EVENTS,
    SET_CALENDAR_MIDWIVES,
} from '@/store/actions/calendar'
import { store } from '@/store/store'
import axios from '@/axios/axios_config'
import swal from 'sweetalert2'
import { vm } from '../../main'
import router from '@/router/router'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

export default {
    state: {
        user: {
            id: null,
            firstName: null,
            lastName: null,
            homePhone: null,
            mobile: null,
            fax: null,
            midwiferyCouncilNumber: null,
            hpicpn: null,
            hpifac: null,
            address: null,
            street_address: null,
            suburb: null,
            city: null,
            postcode: null,
            email: null,
            addresses: [],
            smsTemplates: [],
            signature: null,
            imageThumbnail: null,
            signoffText: null,
            careLocations: [],
            careLocationSelected: null,
            contacts: [],
            careLocationMidwives: [],
            recentlyViewedContacts: [],
            calendar_password: null,
            calendar_default_view: null,
            calendar_reminder_text_setting: null,
            calendar_reminder_email_setting: null,
            calendar_booking_duration: null,
            calendar_antenatal_duration: null,
            calendar_postnatal_duration: null,
            calendar_other_care_duration: null,
            isTest: {}
        },
        appointments: [],
        profileFields: [
            'firstName', 
            'lastName', 
            'email'
        ],
        // set some default appointment types before the 1.2v changes
        appointmentTypes: [
            {
                id: 1,
                name: 'Booking',
                care: true,
                colour: '#3D269E',
                backgroundColour: '#FCD6CD',
                hasSubject: true,
                hasContact: true,
            },
            {
                id: 2,
                name: 'Antenatal',
                care: true,
                colour: '#3D269E',
                backgroundColour: '#E2E4FD',
                hasSubject: false,
                hasContact: true,
            },
            {
                id: 3,
                name: 'Postnatal',
                care: true,
                colour: '#3D269E',
                backgroundColour: '#FFF0F4',
                hasSubject: false,
                hasContact: true,
            },
            {
                id: 4,
                name: 'Other care',
                care: true,
                colour: '#3D269E',
                backgroundColour: '#FBE8FD',
                hasSubject: false,
                hasContact: true,
            },
            {
                id: 5,
                name: 'Private',
                care: false,
                colour: '#4A4A4A',
                backgroundColour: '#E9F2E9',
                hasSubject: true,
                hasContact: false,
            },
            {
                id: 6,
                name: 'Education',
                care: false,
                colour: '#4A4A4A',
                backgroundColour: '#D4FACF',
                hasSubject: true,
                hasContact: false,
            },
            {
                id: 7,
                name: 'Practice meeting',
                care: false,
                colour: '#4A4A4A',
                backgroundColour: '#FDFDE3',
                hasSubject: true,
                hasContact: false,
            },
            {
                id: 8,
                name: 'Other',
                care: false,
                colour: '#4A4A4A',
                backgroundColour: '#F3FFC1',
                hasSubject: true,
                hasContact: false,
            },
        ]
    },
    getters: {
        user: state => {
            return state.user
        },
        profileFields: state => {
            return state.profileFields
        },
        userCareLocations: state => {
            var locationsList = []
            if (state.user.careLocation && state.user.careLocation.length > 0) {
                state.user.CareLocations.forEach(function(element) {
                    locationsList.push({
                        name: element.location.name,
                        idKey: element.location.id_key,
                        id: element.location.id,
                    })
                })

            }
        },
        sharingMidwivesID: state => {
            var midwivesList = []
            if (state.user.careLocationMidwives.length > 0) {
                state.user.careLocationMidwives.forEach(function(item) {
                    midwivesList.push(item.id)
                })
            }
            return midwivesList
        },
        appointments: state => {
            return state.appointments
        },
        recentlyViewedContacts: state => {
            return state.user.recentlyViewedContacts
        },
        appointmentTypes: state => {
            return state.appointmentTypes
        }
    },
    mutations: {
        [SET_USER]: (state, payload) => {
            state.user = payload
        },
        [SET_USER_FIELD]: (state, payload) => {
            state.user[payload.field] = payload.value
        },
        [UPDATE_REFERRAL_CONTACT]: (state, payload) => {
            var i = 0
            do {
                if (state.user.contacts[i].id == payload.id) {
                    vm.$set(state.user.contacts[i].contact, 'name', payload.name)
                    vm.$set(state.user.contacts[i].contact, 'number', payload.number)
                    vm.$set(state.user.contacts[i].contact, 'email', payload.email)
                    i = state.user.contacts.length
                }
                i++
            } while (i < state.user.contacts.length)
        },
        [DELETE_REFERRAL_CONTACT]: (state, payload) => {
            var i = 0
            do {
                if (state.user.contacts[i].id == payload.id) {
                    state.user.contacts.splice(i, 1)
                    i = state.user.contacts.length
                }
                i++
            } while (i < state.user.contacts.length)
        },
        [ADD_REFERRAL_CONTACT]: (state, payload) => {
            var contact_copy = state.user.contacts
            contact_copy.push(payload)
            vm.$set(state.user, 'contacts', contact_copy)
        },
        [SET_APPOINTMENTS]: (state, payload) => {
            state.appointments = payload
        },
        [SET_RECENTLY_VIEWED_CONNECT]: (state, payload) => {
            state.user.recentlyViewedContacts = payload.items
        },
        [ADD_ADDRESS]: (state, payload) => {
            state.user.addresses.push(payload)
        },
        [UPDATE_ADDRESS]: (state, payload) => {
            var i = 0
            do {
                if (state.user.addresses[i].id == payload.id) {
                    vm.$set(state.user.addresses[i], 'id', payload.id)
                    vm.$set(state.user.addresses[i], 'address_name', payload.address_name)
                    vm.$set(state.user.addresses[i], 'address', payload.address)
                    vm.$set(state.user.addresses[i], 'page_id', payload.page_id)
                    i = state.user.addresses.length
                }
                i++
            } while (i < state.user.addresses.length)
        },
        [REMOVE_ADDRESS]: (state, payload) => {
            var i = 0
            do {
                if (state.user.addresses[i].id == payload.id) {
                    state.user.addresses.splice(i, 1)
                    i = state.user.addresses.length
                }
                i++
            } while (i < state.user.addresses.length)
        },
        [ADD_SMS_TEMPLATE]: (state, payload) => {
            state.user.smsTemplates.push(payload)
        },
        [UPDATE_SMS_TEMPLATE]: (state, payload) => {
            var i = 0
            do {
                if (state.user.smsTemplates[i].id == payload.id) {
                    vm.$set(state.user.smsTemplates[i], 'id', payload.id)
                    vm.$set(state.user.smsTemplates[i], 'name', payload.name)
                    vm.$set(state.user.smsTemplates[i], 'message', payload.message)
                    vm.$set(state.user.smsTemplates[i], 'page_id', payload.page_id)
                    i = state.user.smsTemplates.length
                }
                i++
            } while (i < state.user.smsTemplates.length)
        },
        [REMOVE_SMS_TEMPLATE]: (state, payload) => {
            var i = 0
            do {
                if (state.user.smsTemplates[i].id == payload.id) {
                    state.user.smsTemplates.splice(i, 1)
                    i = state.user.smsTemplates.length
                }
                i++
            } while (i < state.user.smsTemplates.length)
        }
    },
    actions: {
        [GET_USER]: ({state, commit, dispatch}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/midwife/?fields=*')
            .then((response) => {
                if (response.data.items) {
                    const tmp_response = response.data.items[0]
                    var tmp_user = {
                        id: tmp_response.id,
                        firstName: tmp_response['profile'].first_name,
                        lastName: tmp_response['profile'].last_name,
                        name: tmp_response.name,
                        homePhone: tmp_response.phone,
                        mobile: tmp_response.mobile,
                        fax: tmp_response.fax,
                        midwiferyCouncilNumber: tmp_response.midwifery_council_number,
                        hpicpn: tmp_response.hpicpn,
                        hpifac: tmp_response.hpifac,
                        address: tmp_response.address,
                        street_address: tmp_response.street_address,
                        suburb: tmp_response.suburb,
                        city: tmp_response.city,
                        postcode: tmp_response.postcode,
                        email: tmp_response['profile'].email,
                        addresses: tmp_response.get_addresses,
                        smsTemplates: tmp_response.get_sms_templates,
                        signature: tmp_response.signature,
                        imageThumbnail: tmp_response.image_thumbnail,
                        signoffText: tmp_response.signoff_text,
                        careLocations: tmp_response.care_locations_related,
                        careLocationSelected: tmp_response.care_location_selected,
                        contacts: tmp_response.contact_related,
                        careLocationMidwives: tmp_response.get_care_location_midwives,
                        recentlyViewedContacts: tmp_response.recently_viewed_contact_woman_related,
                        calendar_password: tmp_response.calendar_password,
                        calendar_default_view: tmp_response.calendar_default_view,
                        calendar_reminder_text_setting: tmp_response.calendar_reminder_text_setting,
                        calendar_reminder_email_setting: tmp_response.calendar_reminder_email_setting,
                        calendar_booking_duration: tmp_response.calendar_booking_duration,
                        calendar_antenatal_duration: tmp_response.calendar_antenatal_duration,
                        calendar_postnatal_duration: tmp_response.calendar_postnatal_duration,
                        calendar_other_care_duration: tmp_response.calendar_other_care_duration,
                        isTest: tmp_response.is_test
                    }
                    commit(SET_USER, tmp_user)
                    // store.dispatch(GET_CALENDAR_EVENTS)
                    store.dispatch(SET_CALENDAR_MIDWIVES)
                    store.dispatch(GET_APPOINTMENTS)
                }
            }).catch((error) => {
                console.log('Error logging in', error)
                // swal.fire({
                //     title: 'Whoops',
                //     text: 'Sorry. There was an issue getting the user',
                //     icon: 'error',
                //     showConfirmButton: false,
                //     showCancelButton: false,
                //     showCloseButton: true,
                // })
                router.push({name: 'Login'})
            })
        },
        [RESET_USER]: ({commit}, payload) => {
            commit(SET_USER, {
                id: null,
                firstName: null,
                lastName: null,
                homePhone: null,
                mobile: null,
                fax: null,
                midwiferyCouncilNumber: null,
                hpicpn: null,
                hpifac: null,
                address: null,
                street_address: null,
                suburb: null,
                city: null,
                postcode: null,
                email: null,
                addresses: [],
                smsTemplates: [],
                signature: null,
                image: null,
                imageThumbnail: null,
                signoffText: null,
                careLocations: [],
                careLocationSelected: null,
                contacts: [],
                careLocationMidwives: [],
                recentlyViewedContacts: [],
                calendar_password: null,
                calendar_default_view: null,
                calendar_reminder_text_setting: null,
                calendar_reminder_email_setting: null,
                calendar_booking_duration: null,
                calendar_antenatal_duration: null,
                calendar_postnatal_duration: null,
                calendar_other_care_duration: null,
                isTest: {}
            })
        },
        [UPDATE_USER]: ({state, commit}, payload) => {
            var payload = payload
            return axios.post(axios.defaults.baseURL + '/api/v2/midwife/' + state.user.id + '/update/', payload)
            .then((response) => {
                if (payload.careLocation) {
                    var find_location = null
                    for (var i = 0; i < state.user.careLocations.length; i++) {
                        if (state.user.careLocations[i].location.id == payload.value) {
                            find_location = state.user.careLocations[i].location
                        }
                    }
                    payload.value = find_location
                    commit(SET_USER_FIELD, payload)
                } else if (payload.updateMultiple) {
                    payload.items.forEach((value) => {
                        commit(SET_USER_FIELD, value)
                    })                    
                } else {
                    commit(SET_USER_FIELD, payload)
                }
            }).catch((problem) => {
                console.log(problem, ' is the problem')
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: problem.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [UPDATE_USER_IMAGE]: ({state, commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/midwife/' + state.user.id + '/update/', 
                payload.sending,
                {
                    headers: {'Content-Type': 'multipart/form-data'} 
                }
            )
            .then((response) => {
                commit(SET_USER_FIELD, {field: payload.updating.field, value: response.data.image})

            }).catch((problem) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: problem.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [UPDATE_REFERRAL_CONTACT]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/contact-book/' + payload.contact_id + '/update/', payload
            ).then((response) => {
                commit(UPDATE_REFERRAL_CONTACT, payload)

            }).catch((problem) => {
                console.log(problem, 'is a problem')
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: problem.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [DELETE_REFERRAL_CONTACT]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/contact-related/' + payload.id + '/delete/'
            ).then((response) => {
                commit(DELETE_REFERRAL_CONTACT, payload)

            }).catch((problem) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: problem.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [ADD_REFERRAL_CONTACT]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/contact-related/add/', payload
            )
            .then((response) => {
                commit(ADD_REFERRAL_CONTACT, response.data)

            }).catch((problem) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: problem.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_APPOINTMENTS]: ({commit}, payload) => {
            return axios.get(
                axios.defaults.baseURL + '/api/v2/calendar-event/just-me/'
            )
            .then((response) => {
                commit(SET_APPOINTMENTS, response.data)

            }).catch((problem) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: problem.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [SET_RECENTLY_VIEWED_CONNECT]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/contact-woman-recently-viewed/add/', payload
            )
            .then((response) => {
                commit(SET_RECENTLY_VIEWED_CONNECT, response.data)

            })
        },
        [ADD_ADDRESS]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/address/add/', payload
            )
            .then((response) => {
                commit(ADD_ADDRESS, response.data)
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [UPDATE_ADDRESS]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/address/update/' + payload.id + '/', payload.value
            )
            .then((response) => {
                commit(UPDATE_ADDRESS, response.data)
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [REMOVE_ADDRESS]: ({commit}, payload) => {
            var cp_payload = payload
            return axios.post(
                axios.defaults.baseURL + '/api/v2/address/remove/' + payload.id + '/', payload
            )
            .then((response) => {

                commit(REMOVE_ADDRESS, cp_payload)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    text: cp_payload.address_name + ' removed successfully',
                    // icon: 'success',
                    customClass: {
                        container: 'sweetalert-modal success',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-success.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    // showCloseButton: true,
                    timer: 2000,
                })
                swal.fire(options)
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.detail,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [ADD_SMS_TEMPLATE]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/sms-template/add/', payload
            )
            .then((response) => {
                commit(ADD_SMS_TEMPLATE, response.data)
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [UPDATE_SMS_TEMPLATE]: ({commit}, payload) => {
            return axios.post(
                axios.defaults.baseURL + '/api/v2/sms-template/update/' + payload.id + '/', payload.value
            )
            .then((response) => {
                commit(UPDATE_SMS_TEMPLATE, response.data)
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [REMOVE_SMS_TEMPLATE]: ({commit}, payload) => {
            var cp_payload = payload
            return axios.post(
                axios.defaults.baseURL + '/api/v2/sms-template/remove/' + payload.id + '/', payload
            )
            .then((response) => {

                commit(REMOVE_SMS_TEMPLATE, cp_payload)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    text: cp_payload.name + ' removed successfully',
                    // icon: 'success',
                    customClass: {
                        container: 'sweetalert-modal success',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-success.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    // showCloseButton: true,
                    timer: 2000,
                })
                swal.fire(options)
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.detail,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        }
    }
}