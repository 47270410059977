export const GET_CONNECT = 'GET_CONNECT'
export const GET_ALL_CONNECT = 'GET_ALL_CONNECT'
export const SET_CONNECT = 'GET_CONNECT'
export const SET_ALL_CONNECT = 'GET_ALL_CONNECT'
export const UPDATE_CONNECT = 'UPDATE_CONNECT'
export const REMOVE_CONNECT = 'REMOVE_CONNECT'
export const GET_FAVOURITE = 'GET_FAVOURITE'
export const SET_FAVOURITE = 'SET_FAVOURITE'
export const ADD_FAVOURITE = 'ADD_FAVOURITE'
export const REMOVE_FAVOURITE = 'REMOVE_FAVOURITE'
export const SAVE_CONNECT = 'SAVE_CONNECT'
export const RETURN_CONNECTION = 'RETURN_CONNECTION'
export const SET_ARCHIVED_CONNECTION = 'SET_ARCHIVED_CONNECTION'
export const ADD_ARCHIVED_CONNECTION = 'ADD_ARCHIVED_CONNECTION'
export const REMOVE_ARCHIVED_CONNECTION = 'REMOVE_ARCHIVED_CONNECTION'
export const AUTO_UPDATE_CONNECT = 'AUTO_UPDATE_CONNECT'
export const GET_CONNECT_NOTIFICATIONS = 'GET_CONNECT_NOTIFICATIONS'
export const SET_CONNECT_NOTIFICATIONS = 'SET_CONNECT_NOTIFICATIONS'
export const ACKNOWLEDGE_NOTIFICATION = 'ACKNOWLEDGE_NOTIFICATION'
export const GET_CONNECT_REFERRALS = 'GET_CONNECT_REFERRALS'
export const SET_CONNECT_REFERRALS = 'SET_CONNECT_REFERRALS'
