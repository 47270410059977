import {
    SET_NOTIFICATION, 
    CLEAR_NOTIFICATION, 
    SHOW_NOTIFICATION, 
    HIDE_NOTIFICATION
} from '@/store/actions/notifications'

export default {
    state: {
        show_notification: false,
        message: null,
        notification_type: null
    },
    getters: {
        show_notification: state => {
            return state.show_notification
        },
        message: state => {
            return state.message
        },
        notification_type: state => {
            return state.notification_type
        }
    },
    mutations: {
        [SET_NOTIFICATION]: ({state}, payload) => {
            state.message = payload.message
            if (payload.type) {
                state.notification_type = payload.type
            } else {
                state.notification_type = null
            }
        },
        [SHOW_NOTIFICATION]: ({state}, payload) => {
            state.show_notification = payload
        }
    },
    actions: {
        [SET_NOTIFICATION]: ({commit}, payload) => {
            commit(SET_NOTIFICATION, payload)
        },
        [CLEAR_NOTIFICATION]: ({commit}) => {
            commit(SET_NOTIFICATION, {message: null, type: null})
        },
        [SHOW_NOTIFICATION]: ({commit}) => {
            commit(SHOW_NOTIFICATION, true)
        },
        [HIDE_NOTIFICATION]: ({commit}) => {
            commit(SHOW_NOTIFICATION, false)
        }
    }
}

