<template>
    <div class='-bg-blue'>
        <div class="pretend-nav"></div>
        <div class='navigation-holder holder' :class='showPopUp'>
            <div class="nav-inner">
                <div class="mobile-logo-holder">
                    <router-link class='logo' to='/'></router-link>
                    <a @click='goingTo({name: "Dashboard"}); hamburger = false;' class='nav-item -active' v-if='isActiveNav("/") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon home-icon">
                                <use xlink:href="#home-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text -white' :class="{'attention': dashboardAttention()}">Dashboard</span>
                    </a>
                    <a @click='goingTo({name: "Calendar"}); hamburger = false;' class='nav-item -active' v-if='isActiveNav("calendar") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon calendar-icon-white">
                                <use xlink:href="#calendar-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text -white' :class="{'attention': calendarAttention()}">Calendar</span>
                    </a>
                    <a @click='goingTo({name: "ReferralsDrafts"}); hamburger = false;' class='nav-item -active' v-if='isActiveNav("referrals") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon referral-edit-icon-white">
                                <use xlink:href="#referral-edit-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text -white' :class="{'attention': referralsAttention()}">Referrals</span>
                    </a>
                    <a @click='goingTo({name: "ConnectContacts"}); hamburger = false;' class='nav-item -active' v-if='isActiveNav("connect") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon connect-icon-white">
                                <use xlink:href="#connect-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text -white' :class="{'attention': connectAttention()}">Connect</span>
                    </a>
                    <a @click='goingTo({name: "MyToDo"}); hamburger = false;' class='nav-item -active' v-if='isActiveNav("to-do") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon to-do-icon">
                                <use xlink:href="#to-do-icon"></use>
                            </svg>
                        </span>
                        <span class='text -white'>To do</span>
                    </a>
                    <a @click='goingTo({name: "MyQuickNote"}); hamburger = false;' class='nav-item -active' v-if='isActiveNav("quick-notes") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon quick-note-icon">
                                <use xlink:href="#quick-note-icon"></use>
                            </svg>
                        </span>
                        <span class='text -white'>Quick note</span>
                    </a>
                    <a @click='goingTo({name: "Settings"}); hamburger = false;' class='settings nav-item -active' v-if='isActiveNav("preferences") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon cog-icon-white">
                                <use xlink:href="#cog-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text -white' :class="{'attention': settingsAttention()}">Preferences</span>
                    </a>
                    <a @click='goingTo({name: "Help"}); hamburger = false;' class='help nav-item -active' v-if='isActiveNav("help") == "-active"'>
                        <span class="icon-holder">
                            <svg class="icon cog-icon-white">
                                <use xlink:href="#cog-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text -white'>Help</span>
                    </a>
                </div>
                <div class="hamburger" @click='hamburger = !hamburger'>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="navigation-links" :class='hamburgerOpen()'>
                    <a @click='goingTo({name: "Dashboard"}); hamburger = false;' class='nav-item' :class='isActiveNav("/")'>
                        <span class="icon-holder">
                            <svg class="icon home-icon">
                                <use xlink:href="#home-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text' :class="{'attention': dashboardAttention()}">Dashboard</span>
                    </a>
                    <a @click='goingTo({name: "Calendar"}); hamburger = false;' class='nav-item' :class='isActiveNav("calendar") ? "-active" : ""'>
                        <span class="icon-holder">
                            <svg class="icon calendar-icon-white">
                                <use xlink:href="#calendar-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text' :class="{'attention': calendarAttention()}">Calendar</span>
                    </a>
                    <a @click='goingTo({name: "ReferralsDrafts"}); hamburger = false;' class='nav-item' :class='isActiveNav("referrals") ? "-active" : ""'>
                        <span class="icon-holder">
                            <svg class="icon referral-edit-icon-white">
                                <use xlink:href="#referral-edit-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text' :class="{'attention': referralsAttention()}">Referrals</span>
                    </a>
                    <a @click='goingTo({name: "ConnectContacts"}); hamburger = false;' class='nav-item' :class='isActiveNav("connect") ? "-active" : ""'>
                        <span class="icon-holder">
                            <svg class="icon connect-icon-white">
                                <use xlink:href="#connect-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text' :class="{'attention': connectAttention()}">Connect</span>
                    </a>
                    <a @click='goingTo({name: "MyToDo"}); hamburger = false;' class='nav-item' :class='isActiveNav("to-do") ? "-active" : ""'>
                        <span class="icon-holder">
                            <svg class="icon to-do-icon">
                                <use xlink:href="#to-do-icon"></use>
                            </svg>
                        </span>
                        <span class='text'>To do</span>
                    </a>
                    <a @click='goingTo({name: "MyQuickNote"}); hamburger = false;' class='nav-item' :class='isActiveNav("quick-notes") ? "-active" : ""'>
                        <span class="icon-holder">
                            <svg class="icon quick-note-icon">
                                <use xlink:href="#quick-note-icon"></use>
                            </svg>
                        </span>
                        <span class='text'>Quick notes</span>
                    </a>
                    <a @click='goingTo({name: "Settings"}); hamburger = false;' class='settings nav-item' :class='isActiveNav("preferences") ? "-active" : ""'>
                        <span class="icon-holder">
                            <svg class="icon cog-icon-white">
                                <use xlink:href="#cog-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text' :class="{'attention': settingsAttention()}">Preferences</span>
                        
                    </a>
                    <a @click='goingTo({name: "Help"}); hamburger = false;' class='help nav-item' :class='isActiveNav("help") ? "-active" : ""'>
                        <span class="icon-holder">
                            <svg class="icon help-icon">
                                <use xlink:href="#help-icon"></use>
                            </svg>
                        </span>
                        <span class='text'>Help</span>
                        
                    </a>
                </div>

                <!-- <div class="navigation-links" :class='hamburgerOpen()' v-if='!user.isTest'> -->
                    
                    <!-- <a @click='goingTo({name: "ReferralsHome"}); hamburger = false;' class='nav-item' :class='isActiveNav("referrals")'>
                        <span class="icon-holder">
                            <svg class="icon referral-edit-icon-white">
                                <use xlink:href="#referral-edit-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text'>Referrals</span>
                    </a> -->
                    <!-- <a @click='goingTo({name: "Settings"}); hamburger = false;' class='settings nav-item' :class='isActiveNav("settings")'>
                        <span class="icon-holder">
                            <svg class="icon cog-icon-white">
                                <use xlink:href="#cog-icon-white"></use>
                            </svg>
                        </span>
                        <span class='text'>Preferences</span>
                        
                    </a> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { vm } from '@/main'
    import { mixin as clickaway } from 'vue-clickaway2'
    import UserMixins from '@/mixins/userMixins'
    export default {
        name: "Navigation",
        data() {
            return {
                backPath: null,
                noBack: ['PasswordResetConfirm', 'Login'],
                showPopUp: '',
                hamburger: false,
                name: null,
                icon: null,
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'popUp',
                ])
        },
        mixins: [
            clickaway,
            UserMixins,
        ],
        methods: {
            findPrevious() {
                const currentPath = this.$route.path
                this.backPath = currentPath.split('/')
                if (this.backPath.length > 2) {
                    this.backPath.pop()
                    this.backPath = this.backPath.join('/')
                } else if (currentPath != '/') {
                    this.backPath = '/'
                } else {
                    this.backPath = null
                }
            },
            goingBack(payload) {
                this.$store.dispatch('setPageTransition', 'slideFadeReverse')
                this.$store.dispatch('setHomeTransition', 'popOutReverse')
                var self = this
                vm.$nextTick().then(function() {
                    self.$router.push(payload)
                })
            },
            goingTo(payload) {
                this.$store.dispatch('setPageTransition', 'slideFade')
                this.$store.dispatch('setHomeTransition', 'slideFade')
                var self = this
                vm.$nextTick().then(function() {
                    self.$router.push(payload)
                })
            },
            isActiveNav(slug) {
                if (this.$route.path.split('/').length > 1 && this.$route.path.split('/')[1] == slug) {
                    return '-active'
                } else {
                    if (this.$route.path == '/' && slug == '/') {
                        return '-active'
                    }
                }
            },
            hamburgerOpen() {
                if (this.hamburger) {
                    return '-active'
                } 
                return 
            },
            missingSetting() {
                if (!this.user.hpncpn) {
                    return true
                } else {
                    return false
                }
            },
            dashboardAttention() {
                return false
            },
            calendarAttention() {
                return false
            },
            referralsAttention() {
                return false
            },
            connectAttention() {
                return false
            },
            settingsAttention() {
                // if (!this.user.hpicpn || !this.user.signature) {
                //     return true
                // }
                if (!this.user.signature) {
                    return true
                    }
                return false
            },
        },
        watch: {
            '$route' (to, from) {
                this.findPrevious()
            }
        },
        updated() {
            if (this.popUp) {
                this.showPopUp = '-pop-up-active'
            } else { 
                this.showPopUp = ''
            }
        },
        created() {
            this.findPrevious()
        }
    }
</script>