export const GET_USER = 'GET_USER'
export const SET_USER = 'SET_USER'
export const RESET_USER = 'RESET_USER'
export const SET_USER_FIELD = 'SET_USER_FIELD'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE'
export const UPDATE_REFERRAL_CONTACT = 'UPDATE_REFERRAL_CONTACT'
export const DELETE_REFERRAL_CONTACT = 'DELETE_REFERRAL_CONTACT'
export const ADD_REFERRAL_CONTACT = 'ADD_REFERRAL_CONTACT'
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS'
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS'
export const SET_RECENTLY_VIEWED_CONNECT = 'SET_RECENTLY_VIEWED_CONNECT'
export const ADD_ADDRESS = 'ADD_ADDRESS'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS'
export const ADD_SMS_TEMPLATE = 'ADD_SMS_TEMPLATE'
export const UPDATE_SMS_TEMPLATE = 'UPDATE_SMS_TEMPLATE'
export const REMOVE_SMS_TEMPLATE = 'REMOVE_SMS_TEMPLATE'
