import {
    GET_MY_QUICK_NOTE,
    GET_CARE_LOCATION_QUICK_NOTE,
    GET_ARCHIVED_QUICK_NOTE,
    SET_MY_QUICK_NOTE,
    SET_CARE_LOCATION_QUICK_NOTE,
    SET_ARCHIVED_QUICK_NOTE,
    ADD_CARE_LOCATION_QUICK_NOTE,
    SET_CARE_LOCATION_QUICK_NOTE_TOTAL,
    EDIT_QUICK_NOTE,
    CREATE_QUICK_NOTE,
    GET_SINGLE_QUICK_NOTE,
    ARCHIVE_QUICK_NOTE,
    UNARCHIVE_QUICK_NOTE,
    GET_QUICK_NOTE_RECORDS,
} from '@/store/actions/quickNote'


import axios from '@/axios/axios_config'
import swal from 'sweetalert2'
// import router from '@/router/router'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

export default {
    state: {
        myQuickNotes: [],
        careLocationQuickNote: [],
        careLocationQuickNoteTotal: 0,
        archivedQuickNote: [],
        quickNoteCategories: [
            {
                id: 1,
                name: 'No action required',
                colour: '#3D269E',
                backgroundColour: '#FCD6CD',
            },
            {
                id: 2,
                name: 'Referral',
                colour: '#3D269E',
                backgroundColour: '#E2E4FD',
            },
            {
                id: 3,
                name: 'Prescription',
                colour: '#3D269E',
                backgroundColour: '#FFF0F4',
            },
            {
                id: 4,
                name: 'Referral consultation',
                colour: '#3D269E',
                backgroundColour: '#FBE8FD',
            },
            {
                id: 5,
                name: 'Other',
                colour: '#4A4A4A',
                backgroundColour: '#E9F2E9',
            },
        ],
    },
    getters: {
        myQuickNotes: state => {
            return state.myQuickNotes
        },
        careLocationQuickNote: state => {
            return state.careLocationQuickNote
        },
        careLocationQuickNoteTotal: state => {
            return state.careLocationQuickNoteTotal
        },
        archivedQuickNote: state => {
            return state.archivedQuickNote
        },
        allQuickNote: state => {
            var tempList = []
            for (var i = 0; i < state.myQuickNotes.length; i++) {
                tempList.push(JSON.parse(JSON.stringify(state.myQuickNotes[i])))
            }
            for (var i = 0; i < state.archivedQuickNote.length; i++) {
                tempList.push(JSON.parse(JSON.stringify(state.archivedQuickNote[i])))
            }
            for (var i = 0; i < state.careLocationQuickNote.length; i++) {
                tempList.push(JSON.parse(JSON.stringify(state.careLocationQuickNote[i])))
            }
            if (!tempList) {
                return []
            }
            return tempList
        },
        quickNoteCategories: state => {
            return state.quickNoteCategories
        },
    },
    mutations: {
        [SET_MY_QUICK_NOTE]: (state, payload) => {
            state.myQuickNotes = payload
        },
        [SET_CARE_LOCATION_QUICK_NOTE]: (state, payload) => {
            state.careLocationQuickNote = payload
        },
        [SET_ARCHIVED_QUICK_NOTE]: (state, payload) => {
            state.archivedQuickNote = payload
        },
        [ADD_CARE_LOCATION_QUICK_NOTE]: (state, payload) => {
            for (var i = 0; i < payload.length; i++) {
                state.careLocationQuickNote.push(payload[i])
            }
        },
        [SET_CARE_LOCATION_QUICK_NOTE_TOTAL]: (state, payload) => {
            state.careLocationQuickNoteTotal = payload
        }
    },
    actions: {


        [GET_MY_QUICK_NOTE]: ({commit}, payload) => {
            var queryString = '/api/v2/quick-note/?limit=99999&order=-date_time'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
            .then((response) => {
                if (response.data) {
                    commit(SET_MY_QUICK_NOTE, response.data.items)
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_CARE_LOCATION_QUICK_NOTE]: ({commit}, payload) => {
            var queryString = '/api/v2/quick-note/?limit=99999&archived=false&care_location_avail=true&order=-date_time'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
            .then((response) => {
                if (response.data) {
                    if (payload.additional) {
                        commit(ADD_CARE_LOCATION_QUICK_NOTE, response.data.items)
                        commit(SET_CARE_LOCATION_QUICK_NOTE_TOTAL, response.data.meta.total_count)
                    } else {
                        commit(SET_CARE_LOCATION_QUICK_NOTE, response.data.items)
                        commit(SET_CARE_LOCATION_QUICK_NOTE_TOTAL, response.data.meta.total_count)
                    }
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                var message = error
                if (error.response) {
                    message = error.response.message
                }
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_ARCHIVED_QUICK_NOTE]: ({commit}, payload) => {
            var queryString = '/api/v2/quick-note/?limit=99999&archived=true&order=-date_time'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
            .then((response) => {
                if (response.data) {
                    commit(SET_ARCHIVED_QUICK_NOTE, response.data.items)
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [CREATE_QUICK_NOTE]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/quick-note/create/', payload)
            .then((response) => {
                if (response.data) {
                    if (payload.loadNew && payload.list == 'myQuickNote') {
                        commit(SET_MY_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'archivedQuickNote') {
                        commit(SET_ARCHIVED_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'careLocationQuickNote') {
                        commit(SET_CARE_LOCATION_QUICK_NOTE, response.data.items)
                    }
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [EDIT_QUICK_NOTE]: ({getters, commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/quick-note/update/' + payload.item.id + '/?limit=99999', payload)
            .then((response) => {
                if (response.data) {
                    if (payload.loadNew && payload.list == 'myQuickNote') {
                        commit(SET_MY_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'archivedQuickNote') {
                        commit(SET_ARCHIVED_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'careLocationQuickNote') {
                        commit(SET_CARE_LOCATION_QUICK_NOTE, response.data.items)
                    }
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_SINGLE_QUICK_NOTE]: ({}, payload) => {

            return axios.get(axios.defaults.baseURL + '/api/v2/quick-note/' + payload.id + '/?care_location_avail=true')
            .then((response) => {
                if (response.data) {
                    return response.data
                }
            }).catch((error) => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.response.data.message
            })
        },
        [ARCHIVE_QUICK_NOTE]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/quick-note/archive/' + payload.item.id + '/', payload)
            .then(response => {
                if (response.data) {
                    if (payload.loadNew && payload.list == 'myQuickNotes') {
                        commit(SET_MY_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'archivedQuickNote') {
                        commit(SET_ARCHIVED_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'careLocationQuickNote') {
                        commit(SET_CARE_LOCATION_QUICK_NOTE, response.data.items)
                    }
                }
            }).catch(error => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [UNARCHIVE_QUICK_NOTE]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/quick-note/unarchive/' + payload.item.id + '/', payload)
            .then(response => {
                if (response.data) {
                    if (payload.loadNew && payload.list == 'myQuickNotes') {
                        commit(SET_MY_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'archivedQuickNote') {
                        commit(SET_ARCHIVED_QUICK_NOTE, response.data.items)
                    }
                    else if (payload.loadNew && payload.list == 'careLocationQuickNote') {
                        commit(SET_CARE_LOCATION_QUICK_NOTE, response.data.items)
                    }
                }
            }).catch(error => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_QUICK_NOTE_RECORDS]: ({commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/quick-note-record/?quick_note=' + payload.id, payload)
            .then(response => {
                if (response.data) {
                    return response.data
                }
            }).catch(error => {
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    text: error.response.data.message,
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
                throw error.response.data.message
            })
        }
    }
}