export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS'
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS'

export const ADD_EVENT = 'ADD_EVENT'
export const EDIT_EVENT = 'EDIT_EVENT'
export const GET_SINGLE_CALENDAR_EVENT = 'GET_SINGLE_CALENDAR_EVENT'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'

export const SET_CALENDAR_MIDWIVES = 'SET_CALENDAR_MIDWIVES'
export const ADD_SELECTED_MIDWIVES = 'ADD_SELECTED_MIDWIVES'
export const SET_SELECTED_MIDWIVES = 'SET_SELECTED_MIDWIVES'
export const REMOVE_SELECTED_MIDWIVES = 'REMOVE_SELECTED_MIDWIVES'

export const SET_MIDWIFE_COLOUR = 'SET_MIDWIFE_COLOUR'

export const SET_CALENDAR_DATE_RANGE = 'SET_CALENDAR_DATE_RANGE'
export const SET_SELECTED_MIDWIVES_IDS = 'SET_SELECTED_MIDWIVES_IDS'

export const SET_CALENDAR_VIEW = 'SET_CALENDAR_VIEW'

export const SEND_MEETING = 'SEND_MEETING'
export const ADD_EVENT_NOW = 'ADD_EVENT_NOW'