export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const AUTO_SIGN_IN = 'AUTO_SIGN_IN'
export const SET_USER = 'SET_USER'
export const OBTAIN_TOKEN = 'OBTAIN_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'
export const INSPECT_TOKEN = 'INSPECT_TOKEN'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const IS_TEST_GROUP = 'IS_TEST_GROUP'