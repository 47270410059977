import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'
import { store } from '@/store/store'
import { INSPECT_TOKEN, IS_TEST_GROUP } from '@/store/actions/auth'

// import Referrals from '@/components/referrals/_Referrals'
// import SentReferrals from '@/components/referrals/SentReferrals'
// import ShowReferral from '@/components/referrals/ShowReferral'
// import ReferralSettings from '@/components/referrals/settings/ReferralSettings'
// import ReferralPreferences from '@/components/settings/ReferralPreferences'
// import Login from '@/components/auth/Login'
// import ForgotPassword from '@/components/auth/ForgotPassword'
// import PasswordResetConfirm from '@/components/auth/PasswordResetConfirm'
// import Settings from '@/components/settings/_Settings'
// import AccountSettings from '@/components/settings/AccountSettings'
// import NeedHelp from '@/components/settings/NeedHelp'
// import AllForms from '@/components/referrals/AllForms'
// import Connect from '@/components/connect/_Connect'
// import ConnectIndex from '@/components/connect/ConnectIndex'
// import ViewConnect from '@/components/connect/ViewConnect'
// import NewConnect from '@/components/connect/NewConnect'
// import NotFound from '@/components/errors/NotFound'
// import Calendar from '@/components/calendar/Calendar'
// import CalendarAddEvent from '@/components/calendar/CalendarAddEvent'
// import CalendarEditEvent from '@/components/calendar/CalendarEditEvent'
// import CalendarSettings from '@/components/calendar/CalendarSettings'
// import Dashboard from '@/components/dashboard/Dashboard'
// import Calendar from '@/components/calendar/_Calendar'
// import ToDo from '@/components/todo/_ToDo'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
    store.dispatch(INSPECT_TOKEN)
    .then((response) => {
        if (response) {
            next()
            return 
        }
        next({name: 'Login'})
    })
}

const ifTestGroup = (to, from, next) => {
    store.dispatch(IS_TEST_GROUP, false)
    .then((response) => {
        if (response) {
            next()
            return
        }
        next({name: 'NotFound'})
    })
}

// cons = (to, from, next) => {
//     store.dispatch(IS_TEST_GROUP, 'quick_note')
//     .then((response) => {
//         if (response) {
//             next()
//             return
//         }
//         next({name: 'NotFound'})
//     })
// }

// const ifTestGroupToDo = (to, from, next) => {
//     store.dispatch(IS_TEST_GROUP, 'to_do')
//     .then((response) => {
//         if (response) {
//             next()
//             return
//         }
//         next({name: 'NotFound'})
//     })
// }

export default new Router({
    // scrollBehavior(from, to, savedPosition) {
    //     var appTest = document.getElementById('app')
    //     appTest.scrollTop = 0
    //     return {x: 0, y: 0} // pointless return value
    // },
    routes: [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackPrefetch: true */  '@/components/auth/Login')
    },
    {
        path: '/login/forgot-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackPrefetch: true */  '@/components/auth/ForgotPassword')
    },
    {
        path: '/preferences',
        name: 'Settings',
        redirect: {name: "AccountSettings"},
        component: () => import(/* webpackPrefetch: true */  '@/components/settings/_Settings'),
        meta: {
            component: 'Settings'
        },
        // beforeEnter: ifAuthenticated
    },
    {
        path: '/preferences/account',
        name: 'AccountSettings',
        component: () => import(/* webpackPrefetch: true */  '@/components/settings/_Settings'),
        meta: {
            component: 'Settings',
            subnav: 'account-settings',
            tab: 1,
        },
        beforeEnter: ifAuthenticated
    },
     {
        path: '/preferences/referrals',
        name: 'ReferralPreferences',
        component: () => import(/* webpackPrefetch: true */  '@/components/settings/_Settings'),
        meta: {
            component: 'Settings',
            subnav: 'referral-preferences',
            tab: 2,
        },
        beforeEnter: ifAuthenticated
    },
    {
        path: '/preferences/calendar',
        name: 'CalendarPreferences',
        component: () => import(/* webpackPrefetch: true */  '@/components/settings/_Settings'),
        meta: {
            component: 'Settings',
            subnav: 'calendar-preferences',
            tab: 3,
        },
        beforeEnter: ifAuthenticated
    },
    {
        path: '/preferences/help',
        name: 'NeedHelp',
        component: () => import(/* webpackPrefetch: true */  '@/components/settings/_Settings'),
        meta: {
            component: 'Settings',
            subnav: 'need-help',
            tab: 5,
        },
        // beforeEnter: ifAuthenticated
    },
    {
        path: '/auth/password/reset/confirm/:uid/:token',
        name: 'PasswordResetConfirm',
        component: () => import(/* webpackPrefetch: true */  '@/components/auth/PasswordResetConfirm')
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackPrefetch: true */  '@/components/dashboard/Dashboard'),
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/referrals',
        name: 'ReferralsHome',
        // component: () => import(/* webpackPrefetch: true */  '@/components/referrals/_Referrals'),
        redirect: {name: "ReferralsDrafts"},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/referrals/drafts',
        name: 'ReferralsDrafts',
        component: () => import(/* webpackPrefetch: true */  '@/components/referrals/_Referrals'),
        beforeEnter: ifAuthenticated,
        meta: {
            tab: 1,
            noFilter: true,
        }
    },
    {
        path: '/referrals/completed',
        name: 'ReferralsCompleted',
        component: () => import(/* webpackPrefetch: true */  '@/components/referrals/_Referrals'),
        beforeEnter: ifAuthenticated,
        meta: {
            tab: 2,
            noFilter: true,
        }
    },
    {
        path: '/referrals/referral-preferences',
        name: 'ReferralSettings',
        component: () => import(/* webpackPrefetch: true */  '@/components/referrals/_Referrals'),
        beforeEnter: ifAuthenticated,
        meta: {
            tab: 3,
            noFilter: true,
        }
    },
    {
        path: '/referrals/new-referral',
        name: 'NewReferral',
        component: () => import(/* webpackPrefetch: true */  '@/components/referrals/_Referrals'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/referrals/completed/:id',
        name: 'ShowReferral',
        component: () => import(/* webpackPrefetch: true */  '@/components/referrals/_Referrals'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/referrals/sent-referrals',
        name: 'SentReferrals',
        component: () => import(/* webpackPrefetch: true */  '@/components/referrals/SentReferrals'),
        beforeEnter: ifAuthenticated
    },
    // {
    //     path: '/all-forms/:id',
    //     name: 'OneForm',
    //     component: OneForm,
    //     beforeEnter: ifAuthenticated
    // },
    {
        path: '/referrals/all-forms',
        name: 'AllForms',
        component: () => import(/* webpackPrefetch: true */  '@/components/referrals/_Referrals'),
        beforeEnter: ifAuthenticated,
        meta: {
            tab: 4,
            noFilter: true,
        }
    },
    {
        path: '/connect',
        name: 'ConnectIndex',
        // component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        redirect: {name: "ConnectContacts"},
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/contacts',
        name: 'ConnectContacts',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        beforeEnter: ifAuthenticated,
        meta: {
            tab: 1,
        }
    },
    // {
    //     path: '/connect/contacts/new',
    //     name: 'NewConnect',
    //     component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
    //     meta: {
    //         component: 'PopUp'
    //     },
    //     beforeEnter: multiguard([ifAuthenticated, ifTestGroup])
    // },
    {
        path: '/connect/contacts/:id',
        name: 'ViewConnect',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            name: 'ViewConnect'
        },
        redirect: {name: "ViewConnect_Contact", id: ':id'},
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/contacts/:id/contact',
        name: 'ViewConnect_Contact',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            subnav: 'contact',
            name: 'ViewConnect'
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/contacts/:id/mis-information',
        name: 'ViewConnect_MISInfo',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            subnav: 'misinfo',
            name: 'ViewConnect'
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/contacts/:id/referrals',
        name: 'ViewConnect_Referrals',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            subnav: 'referrals',
            name: 'ViewConnect'
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/contacts/:id/quick-notes',
        name: 'ViewConnect_QuickNotes',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            subnav: 'quick-notes',
            name: 'ViewConnect'
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/favourites',
        name: 'ConnectFavourites',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        beforeEnter: ifAuthenticated, 
        meta: {
            tab: 2,
        }
    },
    {
        path: '/connect/archived',
        name: 'ConnectArchived',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        beforeEnter: ifAuthenticated, 
        meta: {
            tab: 3,
        }
    },
    {
        path: '/connect/archived/:id',
        name: 'ViewConnectArchived',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            name: 'ViewConnect'
        },
        redirect: {name: "ViewConnectArchived_Contact", id: ':id'},
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/archived/:id/contact',
        name: 'ViewConnectArchived_Contact',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            subnav: 'contact',
            name: 'ViewConnect'
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/archived/:id/referrals',
        name: 'ViewConnectArchived_Referrals',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        meta: {
            component: 'PopUp',
            subnav: 'referrals',
            name: 'ViewConnect'
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/messages',
        name: 'ConnectMessages',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/connect/messages/new',
        name: 'ConnectMessagesNew',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        beforeEnter: ifAuthenticated, 
        meta: {
            component: 'PopUp',
        },
    },
    {
        path: '/connect/messages/:id',
        name: 'ConnectMessagesSent',
        component: () => import(/* webpackPrefetch: true */  '@/components/connect/_Connect'),
        beforeEnter: ifAuthenticated, 
        meta: {
            component: 'PopUp',
        },
    },
    {
        path: '/preferences/smstemplates',
        name: 'ConnectMessagesTemplates',
        component: () => import(/* webpackPrefetch: true */  '@/components/settings/_Settings'),
        meta: {
            subnav: 'sms-templates',
            tab: 4,
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/calendar',
        name: 'Calendar',
        meta: {
            CalendarInView: true
        },
        component: () => import(/* webpackPrefetch: true */  '@/components/calendar/_Calendar'),
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/calendar/add',
        name: 'CalendarAddEvent',
        component: () => import(/* webpackPrefetch: true */  '@/components/calendar/_Calendar'),
        meta: {
            component: 'PopUp',
            edit: false,
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/calendar/edit',
        name: 'CalendarEditEvent',
        component: () => import(/* webpackPrefetch: true */  '@/components/calendar/_Calendar'),
        meta: {
            component: 'PopUp',
            edit: true,
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/calendar/settings',
        name: 'CalendarSettings',
        component: () => import(/* webpackPrefetch: true */  '@/components/calendar/_Calendar'),
        meta: {
            // component: 'PopUp'
            noFilter: true,
        },
        beforeEnter: ifAuthenticated 
    },
    {
        path: '/to-do',
        name: 'ToDo',
        component: () => import(/* webpackPrefetch: true */  '@/components/todo/_ToDo'),
        redirect: {name: "MyToDo"},
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/to-do/mine',
        name: 'MyToDo',
        component: () => import(/* webpackPrefetch: true */  '@/components/todo/_ToDo'),
        meta: {
            tab: 1,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/to-do/completed',
        name: 'CompletedToDo',
        component: () => import(/* webpackPrefetch: true */  '@/components/todo/_ToDo'),
        meta: {
            tab: 2,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/to-do/care-location',
        name: 'CareLocationToDo',
        component: () => import(/* webpackPrefetch: true */  '@/components/todo/_ToDo'),
        meta: {
            tab: 2,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/to-do/edit/:id',
        name: 'ToDoEdit',
        component: () => import(/* webpackPrefetch: true */  '@/components/todo/_ToDo'),
        meta: {
            component: 'PopUp',
            edit: true,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/to-do/create',
        name: 'ToDoCreate',
        component: () => import(/* webpackPrefetch: true */  '@/components/todo/_ToDo'),
        meta: {
            component: 'PopUp',
            create: true,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/quick-notes',
        name: 'QuickNote',
        component: () => import(/* webpackPrefetch: true */  '@/components/quickNote/_QuickNote'),
        redirect: {name: "MyQuickNote"},
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/quick-notes/mine',
        name: 'MyQuickNote',
        component: () => import(/* webpackPrefetch: true */  '@/components/quickNote/_QuickNote'),
        meta: {
            tab: 1,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/quick-notes/care-location',
        name: 'CareLocationQuickNote',
        component: () => import(/* webpackPrefetch: true */  '@/components/quickNote/_QuickNote'),
        meta: {
            tab: 2,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
    path: '/quick-notes/archived',
    name: 'ArchivedQuickNote',
    component: () => import(/* webpackPrefetch: true */  '@/components/quickNote/_QuickNote'),
    meta: {
        tab: 3,
    },
    beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/quick-notes/view/:quickNoteId',
        name: 'QuickNoteView',
        component: () => import(/* webpackPrefetch: true */  '@/components/quickNote/_QuickNote'),
        meta: {
            component: 'PopUp',
            edit: true,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/quick-notes/edit/:quickNoteId',
        name: 'QuickNoteEdit',
        component: () => import(/* webpackPrefetch: true */  '@/components/quickNote/_QuickNote'),
        meta: {
            component: 'PopUp',
            edit: true,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/quick-notes/create',
        name: 'QuickNoteCreate',
        component: () => import(/* webpackPrefetch: true */  '@/components/quickNote/_QuickNote'),
        meta: {
            component: 'PopUp',
            create: true,
        },
        beforeEnter: multiguard([ifAuthenticated])
    },
    {
        path: '/help',
        name: 'Help',
        component: () => import (/* webpackPrefetch: true */ '@/components/help/_Help'),
        redirect: {name: 'TrainingVideos'},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/help/training-videos',
        name: 'TrainingVideos',
        component: () => import(/* webpackPrefetch: true */ '@/components/help/_Help'),
        meta: {
            tab: 1,
        },
        beforeEnter: ifAuthenticated
    },
        {
            path: '/help/mis-training-videos',
            name: 'MISTrainingVideos',
            component: () => import(/* webpackPrefetch: true */ '@/components/help/_Help'),
            meta: {
                tab: 2,
            },
            beforeEnter: ifAuthenticated
        },
    {
        path: '/help/global-training-videos',
        name: 'GlobalTrainingVideos',
        component: () => import(/* webpackPrefetch: true */ '@/components/help/_Help'),
        meta: {
            tab: 2,
        },
        beforeEnter: ifAuthenticated
    },
    {
        path: '/help/need-help',
        name: 'NeedHelp2',
        component: () => import(/* webpackPrefetch: true */ '@/components/help/_Help'),
        meta: {
            tab: 3,
        },
        beforeEnter: ifAuthenticated
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackPrefetch: true */  '@/components/errors/NotFound'),
    },
    ]
})

