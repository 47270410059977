import { GET_HELP, SET_HELP } from '@/store/actions/needHelp'
import swal from 'sweetalert2'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'
import axios from '@/axios/axios_config'

export default {
    state: {
        helpText: null,
    },
    getters: {
        helpText: state => {
            return state.helpText
        }
    },
    mutations: {
        [SET_HELP]: (state, payload) => {
            state.helpText = payload
        }
    },
    actions: {
        [GET_HELP]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/need-help/')
            .then((response) => {
                commit(SET_HELP, response.data)
            })
            .catch((error) => {
                var message = error.response
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'warning',
                    customClass: {
                        container: 'sweetalert-modal warning',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-warning.svg',
                    text: message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        }
    }
}