import {
    GET_WOMEN,
    SET_WOMEN,
    GET_CHILDREN,
    SET_CHILDREN,
    GET_ALL_WOMEN,
    SET_ALL_WOMEN,
    GET_PREVIOUS_PREGNANCIES,
    SET_PREVIOUS_PREGNANCIES,
} from '@/store/actions/woman'


import axios from '@/axios/axios_config'
import { store } from '@/store/store'

export default {
    state: {
        women: [],
        children: [],
        allWomen: [],
        previousObstetricHistory: [],
    },
    getters: {
        women: state => {
            return state.women
        },
        children: state => {
            return state.children
        },
        allWomen: state => {
            return state.allWomen
        },
        previousObstetricHistory: state => {
            return state.previousObstetricHistory
        }
    },
    mutations: {
        [SET_WOMEN]: (state, payload) => {
            state.women = payload
        },
        [SET_CHILDREN]: (state, payload) => {
            state.children = payload
        },
        [SET_ALL_WOMEN]: (state, payload) => {
            state.allWomen = payload
        },
        [SET_PREVIOUS_PREGNANCIES]: (state, payload) => {
            state.previousObstetricHistory = payload
        }
    },
    actions: {
        [GET_WOMEN]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/woman-care-location/' + store.state.user.user.careLocationSelected.id_key + '/?limit=999999')
            .then((response) => {
                commit(SET_WOMEN, response.data.items)
            })
        },
        [GET_ALL_WOMEN]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/woman/?limit=999999')
            .then((response) => {
                commit(SET_ALL_WOMEN, response.data.items)
            })
        },
        [GET_CHILDREN]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/baby-care-location/' + store.state.user.user.careLocationSelected.id_key + '/?limit=999999')
            .then((response) => {
                commit(SET_CHILDREN, response.data.items)
            })
        },
        [GET_PREVIOUS_PREGNANCIES]: ({commit}, payload) => {
            var url = axios.defaults.baseURL + '/api/v2/previous-pregnancies/'
            if (payload.params) {
                url += payload.params
            }
            return axios.get(url)
            .then((response) => {
                commit(SET_PREVIOUS_PREGNANCIES, response.data.items)
                return response.data.items
            })
            .catch((error) => {
                console.log('ERROR')
                console.log(error)
            })
        }
    }
}