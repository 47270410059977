import {
    CREATE_MEETING
} from '@/store/actions/videoMeeting'

import axios from '@/axios/axios_config'
import swal from 'sweetalert2'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        [CREATE_MEETING]: ({}, payload) => { 
            return axios.post(axios.defaults.baseURL + '/api/v2/video-meeting/create/', payload).then(response => {
                if (response.data.success) {
                    // console.log(response.data['meeting_url'], response.data.meeting_url)
                    return response.data.meeting_url
                } else {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Error',
                        text: 'A meeting room could not be generated at this time',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        showConfirmButton: true,
                        showCancelButton: false,
                    })
                    swal.fire(options)
                    return false
                }
            })
        }
    }
}