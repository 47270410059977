import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import notifications from './modules/notifications' 
import user from './modules/user'
import needHelp from './modules/needHelp'
import transitions from './modules/transitions'
import referrals from './modules/referrals'
import woman from './modules/woman'
import connections from './modules/connect'
import calendar from './modules/calendar'
import sms from './modules/sms'
import videoMeeting from './modules/videoMeeting'
import toDo from './modules/toDo'
import quickNote from './modules/quickNote'
import help from './modules/help'
import ethnicityCodes from './ethnicityCodes'
import languageCodes from './languageCodes'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        auth,
        notifications,
        calendar,
        user,
        needHelp,
        transitions,
        referrals,
        woman,
        connections,
        sms,
        videoMeeting,
        toDo,
        quickNote,
        help,
        ethnicityCodes,
        languageCodes,
    },
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        
    }
})