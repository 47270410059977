import {
    GET_TRAINING_VIDEOS,
    SET_TRAINING_VIDEOS,
    GET_TRAINING_VIDEO_CATEGORIES,
    SET_TRAINING_VIDEO_CATEGORIES,
    GET_GLOBAL_TRAINING_VIDEOS,
    SET_GLOBAL_TRAINING_VIDEOS,
    GET_GLOBAL_TRAINING_VIDEO_CATEGORIES,
    SET_GLOBAL_TRAINING_VIDEO_CATEGORIES,

    SET_CURRENT_TRAINING_VIDEO,
} from '@/store/actions/help'

import axios from '@/axios/axios_config'
import swal from 'sweetalert2'
import router from '@/router/router'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

export default {
    state: {
        trainingVideos: [],
        trainingVideoCategories: [
            {
                id: 1,
                category: 'General',
            },
            {
                id: 2,
                category: 'Referrals',
            },
            {
                id: 3,
                category: 'Quick notes',
            },
            {
                id: 4,
                category: 'Settings',
            },
            {
                id: 5,
                category: 'Calendar',
            },
            {
                id: 6,
                category: 'Connect',
            },
            {
                id: 7,
                category: 'Messaging',
            },
            {
                id: 8,
                category: 'Video',
            },
        ],
        globalTrainingVideos: [],
        globalTrainingVideoCategories: [],
        trainingVideoCategoryIcons: {
            'General': 'home-icon-purple',
            'Referrals': 'referral-edit-icon-purple',
            'Quick notes': 'quick-note-icon-purple',
            'Settings': 'cog-icon-purple',
            'Calendar': 'calendar-icon-purple',
            'Connect': 'call-icon',
            'Messaging': 'text-message-icon',
            'Video': 'video-icon',
        },
        currentTrainingVideo: null,
    },
    getters: {
        trainingVideos: state => {
            return state.trainingVideos
        },
        trainingVideoCategories: state => {
            return state.trainingVideoCategories
        },
        trainingVideoCategoryIcons: state => {
            return state.trainingVideoCategoryIcons
        },
        globalTrainingVideos: state => {
            return state.globalTrainingVideos
        },
        globalTrainingVideoCategories: state => {
            return state.globalTrainingVideoCategories
        },
        misTrainingVideoCategoryIcons: state => {
            return state.misTrainingVideoCategoryIcons
        },
        currentTrainingVideo: state => {
            return state.currentTrainingVideo
        }
    },
    mutations: {
        [SET_TRAINING_VIDEOS]: (state, payload) => {
            state.trainingVideos = payload
        },
        [SET_TRAINING_VIDEO_CATEGORIES]: (state, payload) => {
            state.trainingVideoCategories = payload
        },
        [SET_GLOBAL_TRAINING_VIDEOS]: (state, payload) => {
            state.globalTrainingVideos = payload
        },
        [SET_GLOBAL_TRAINING_VIDEO_CATEGORIES]: (state, payload) => {
            state.globalTrainingVideoCategories = payload
        },
        [SET_CURRENT_TRAINING_VIDEO]: (state, payload) => {
            state.currentTrainingVideo = payload
        },
    },
    actions: {
        [GET_TRAINING_VIDEOS]: ({commit}, payload) => {
            var queryString = '/api/v2/training-videos/?limit=999999'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
            .then((response) => {
                if (response.data.items) {
                    commit(SET_TRAINING_VIDEOS, response.data.items)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_TRAINING_VIDEO_CATEGORIES]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/training-video-categories/?limit=999999')
            .then((response) => {
                if (response.data.items) {
                    commit(SET_TRAINING_VIDEO_CATEGORIES, response.data.items)
                }
            })
            .catch((error) => {
                console.log(error)
                var options = Object.assign({}, sweetalert_options)
                options = Object.assign(options, {
                    title: 'Whoops',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                })
                swal.fire(options)
            })
        },
        [GET_GLOBAL_TRAINING_VIDEOS]: ({ commit }, payload) => {
            var queryString = '/api/v2/global-training-videos/?limit=999999'
            if (payload.queryParams) {
                queryString += payload.queryParams
            }
            return axios.get(axios.defaults.baseURL + queryString)
                .then((response) => {
                    if (response.data.items) {
                        commit(SET_GLOBAL_TRAINING_VIDEOS, response.data.items)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                })
        },
        [GET_GLOBAL_TRAINING_VIDEO_CATEGORIES]: ({ commit }) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/global-training-video-categories/?limit=999999')
                .then((response) => {
                    if (response.data.items) {
                        commit(SET_GLOBAL_TRAINING_VIDEO_CATEGORIES, response.data.items)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Whoops',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                })
        },
    }
}