import {
    GET_REFERRALS,
    GET_SINGLE_REFERRAL,
    SET_SINGLE_REFERRAL,
    GET_REFERRALS_DRAFTS,
    EDIT_REFERRAL,
    DELETE_REFERRAL_DRAFT,
    SET_REFERRALS,
    SET_ERROR_REFERRALS,
    APPEND_REFERRALS,
    SET_REFERRALS_TOTAL,
    SET_REFERRALS_DRAFTS,
    SET_REFERRALS_DRAFTS_TOTAL,
    CREATE_REFERRAL,
    CREATE_REFERRAL_SIMPLE,
    SET_NUMBER_REQUIRED,
    SET_NUMBER_REMAINING,
    SHOW_NUMBER_REQUIRED,
    REQUIRED_FIELD_JUMP_LIST,
    SET_JUMP_LIST_NUMBER,
    SET_REFERRAL_SECTIONS_OPEN,
    RESET_REQUIRED,
    SET_ATTEMPTED_SUBMIT,
    UPDATE_REFERRAL_DATA,
    SET_REFERRAL_DATA,
    SET_REFERRAL_SELECTED_DATA,
    GET_REFERRAL_DATA,
    RESET_REFERRAL_DATA,
    SEND_REFERRAL,
    RESEND_REFERRAL,
    SET_IS_EDIT,
    SET_SEND_TO_LIST,
    ADD_SEND_TO_LIST,
    REMOVE_SEND_TO_LIST,
    REMOVE_ATTACHMENT,
    SET_STEP_NUMBER,
    MARK_REFERRAL_COMPLETED,
    GET_REFERRAL_ERROR_COUNT,
    SET_REFERRAL_ERROR_COUNT,
    UPDATE_SELECTED_PREVIOUS_PREGNANCIES,
    UPDATE_SELECTED_PREVIOUS_PREGNANCIES_LIST,
    ASSIGN_REFERRAL
} from '@/store/actions/referrals'
import { store } from '@/store/store'
import axios from '@/axios/axios_config'
import { vm } from '../../main'
import swal from 'sweetalert2'
import route from '@/router/router'
import { sweetalert_options } from '@/sweetalert/sweetalert_config'

var qs = require('qs');

export default {
    state: {
        referrals: [],
        referralsTotal: null,
        errorReferrals: [],
        referralsErrorCount: 0,
        referralsDrafts: [],
        referralsDraftsTotal: [],
        singleReferral: {},
        numberOfRequired: 0,
        numberOfRemaining: 0,
        showNumberRequired: false,
        requiredFieldJumpList: [],
        jumpListNumber: 0,
        sectionsOpen: [],
        attemptedSubmit: false,
        isEdit: false,
        referralData: {
            woman: null,
            child: null,
            referralSelected: null,
            formData: {},
            previewLink: null,
            contactSelected: null,
            recordID: null,
            referralComplete: false,
            referralResults: {},
            attachments: [],
            currentStep: 1,
            selectedPreviousPregnancies: {},
            selectedPreviousPregnanciesList: {},

        },
        sendToList: [],
        ermsPriorities: [
            // {code: 'A', text: 'ASAP'},
            {code: 'R', text: 'Routine'},
            {code: 'S', text: 'Semi Urgent'},
            {code: 'U', text: 'Urgent'},
            // {code: 'H', text: 'High suspicion of cancer'},
        ],
        ermsDispositions: [
            // {'code': 'OAS', 'requested_action': 'Clinic assessment'},
            // {'code': 'INF', 'requested_action': 'For your information'},
            // {'code': 'RAP', 'requested_action': 'Reassess priority'},
            // {'code': 'SWA', 'requested_action': 'Written advice only'},
            // {'code': 'PET', 'requested_action': 'Programme enrolment'},
            // {'code': 'CPA', 'requested_action': 'Please contact patient to make an appointment'}

            {'code': 'AAS', 'requested_action': 'Acute assessment'},
            {'code': 'OAS', 'requested_action': 'Clinic assessment'},
            {'code': 'CAS', 'requested_action': 'Community assessment'},
            {'code': 'ADM', 'requested_action': 'Admission'},
            {'code': 'SWA', 'requested_action': 'Written advice only'},
            {'code': 'INV', 'requested_action': 'Investigation only'},
            {'code': 'INF', 'requested_action': 'For your information'},
            {'code': 'SRB', 'requested_action': 'Services requested as described below'},
            {'code': 'AOA', 'requested_action': 'If investigation suggests malignancy arrange outpatient assessment NAF No further action after investigation, I will follow up'},
            {'code': 'PMA', 'requested_action': 'Patient will make an appointment'},
            {'code': 'CPA', 'requested_action': 'Please contact patient to make an appointment'},
            {'code': 'PAA', 'requested_action': 'Patient already has an appointment'},
            {'code': 'NSR', 'requested_action': 'No services required (action taken in practice)'},
            {'code': 'RAP', 'requested_action': 'Reassess priority'},
            {'code': 'PET', 'requested_action': 'Programme enrolment'},

        ],
    },
    getters: {
        referrals: state => {
            return state.referrals
        },
        referralsTotal: state => {
            return state.referralsTotal
        },
        errorReferrals: state => {
            return state.errorReferrals
        },
        referralsDrafts: state => {
            return state.referralsDrafts
        },
        referralsDraftsTotal: state => {
            return state.referralsDraftsTotal
        },
        singleReferral: state => {
            return state.singleReferral
        },
        numberOfRequired: state => {
            return state.numberOfRequired
        },
        numberOfRemaining: state => {
            return state.numberOfRemaining
        },
        showNumberRequired: state => {
            return state.showNumberRequired
        },
        attemptedSubmit: state => {
            return state.attemptedSubmit
        },
        referralData: state => {
            return state.referralData
        },
        isEdit: state => {
            return state.isEdit
        },
        requiredFieldJumpList: state => {
            return state.requiredFieldJumpList
        },
        jumpListNumber: state => {
            return state.jumpListNumber
        },
        sectionsOpen: state => {
            return state.sectionsOpen
        },
        sendToList: state => {
            return state.sendToList
        },
        referralsErrorCount: state => {
            return state.referralsErrorCount
        },
        ermsPriorities: state => {
            return state.ermsPriorities
        },
        ermsDispositions: state => {
            return state.ermsDispositions
        }
    },
    mutations: {
        [SET_NUMBER_REQUIRED]: (state, payload) => {
            state.numberOfRequired = payload
        },
        [SET_NUMBER_REMAINING]: (state, payload) => {
            state.numberOfRemaining = payload
        },
        [SHOW_NUMBER_REQUIRED]: (state, payload) => {
            state.showNumberRequired = payload
        },
        [SET_ATTEMPTED_SUBMIT]: (state, payload) => {
            state.attemptedSubmit = payload
        },
        [UPDATE_REFERRAL_DATA]: (state, payload) => {
            state.referralData[payload.field] = payload.value
        },
        [SET_REFERRAL_DATA]: (state, payload) => {
            state.referralData = payload
        },
        [SET_REFERRAL_SELECTED_DATA]: (state, payload) => {
            state.referralData.referralSelected.referral = payload
        },
        [SET_REFERRALS]: (state, payload) => {
            state.referrals = payload
        },
        [SET_ERROR_REFERRALS]: (state, payload) => {
            state.errorReferrals = payload
        },
        [APPEND_REFERRALS]: (state, payload) => {
            for (var i = 0; i < payload.length; i++) {
                state.referrals.push(payload[i])
            }
        },
        [SET_REFERRALS_TOTAL]: (state, payload) => {
            state.referralsTotal = payload
        },
        [SET_REFERRALS_DRAFTS]: (state, payload) => {
            state.referralsDrafts = payload
        },
        [SET_REFERRALS_DRAFTS_TOTAL]: (state, payload) => {
            state.referralsDraftsTotal = payload
        },
        [SET_SINGLE_REFERRAL]: (state, payload) => {
            state.singleReferral = payload
        },
        [DELETE_REFERRAL_DRAFT]: (state, payload) => {
            if (state.referralsDrafts.length > 0) {
                var i = 0
                do {
                    if (state.referralsDrafts[i].id == payload.id) {
                        state.referralsDrafts.splice(i, 1)
                        i = state.referralsDrafts.length
                        state.referralsDraftsTotal -= 1
                    }
                    i++
                } while (i < state.referralsDrafts.length)
            }
        },
        [SET_IS_EDIT]: (state, payload) => {
            state.isEdit = payload
        },
        [REQUIRED_FIELD_JUMP_LIST]: (state, payload) => {
            state.requiredFieldJumpList = payload
        },
        [SET_JUMP_LIST_NUMBER]: (state, payload) => {
            state.jumpListNumber = payload
        },
        [SET_REFERRAL_SECTIONS_OPEN]: (state, payload) => {
            state.sectionsOpen = payload
        },
        [SET_SEND_TO_LIST]: (state, payload) => {
            state.sendToList = payload
        },
        [ADD_SEND_TO_LIST]: (state, payload) => {
            var tempList = state.sendToList
            tempList.push(payload)
            state.sendToList = tempList
        },
        [REMOVE_SEND_TO_LIST]: (state, payload) => {
            var i = 0
            do {
                if (state.sendToList[i].id == payload) {
                    state.sendToList.splice(i, 1)
                    i = state.sendToList.length
                }
                i++
            } while (i < state.sendToList.length)
        },
        [SET_STEP_NUMBER]: (state, payload) => {
            state.referralData.currentStep = payload
        },
        [SET_REFERRAL_ERROR_COUNT]: (state, payload) => {
            state.referralsErrorCount = payload
        },
        [UPDATE_SELECTED_PREVIOUS_PREGNANCIES]: (state, payload) => {
            state.referralData.selectedPreviousPregnancies[payload.id] = payload.value
        },
        [UPDATE_SELECTED_PREVIOUS_PREGNANCIES_LIST]: (state, payload) => {
            vm.$set(state.referralData.selectedPreviousPregnanciesList, payload.id, payload.value)
        },
    },
    actions: {
        // have already got the referrals form the user by getting a more robust response for care locations
        [GET_REFERRALS]: ({state, commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/referral-record/' + payload.query)
                .then((response) => {
                    if ('getErrors' in payload) {
                        commit(SET_ERROR_REFERRALS, response.data.items)
                        commit(SET_REFERRAL_ERROR_COUNT, response.data.meta.total_count)
                    } else {
                        if (payload.initial) {
                            commit(SET_REFERRALS, response.data.items)
                        } else {
                            commit(APPEND_REFERRALS, response.data.items)
                        }
                        commit(SET_REFERRALS_TOTAL, response.data.meta.total_count)
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        },
        // this gets a blank template data
        [GET_REFERRAL_DATA]: ({state, commit}, payload) => {
            return axios.get(payload.detail_url)
                .then((response) => {
                    commit(SET_REFERRAL_SELECTED_DATA, response.data)
                    return 'thing'
                })
                .catch((error) => {
                    console.log(error)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Something has gone wrong retrieving a referral - ' + error.message
                    })
                    swal.fire(options)
                    throw error.message
                })
        },
        [GET_SINGLE_REFERRAL]: ({commit}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/referral-record/' + payload + '/')
                .then((response) => {
                    commit(SET_SINGLE_REFERRAL, response.data)

                })
                .catch((error) => {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Something has gone wrong retrieving a referral - ' + error.message
                    })
                    swal.fire(options)
                    throw error.message
                    })
        },
        [EDIT_REFERRAL]: ({commit, dispatch}, payload) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/referral-record/' + payload.id + '/')
                .then((response) => {
                    var referral_dict = {
                        woman: response.data.data._woman.value,
                        child: response.data.data._child.value,
                        referralSelected: {
                            referral: response.data.referral,
                        },
                        formData: response.data.data,
                        contactSelected: null,
                        recordID: response.data.id,
                        referralComplete: false,
                        referralResults: {},
                        attachments: response.data.attachments,
                        currentStep: response.data.current_step,
                        selectedPreviousPregnancies: response.data.data._selected_previous_pregnancies,
                        selectedPreviousPregnanciesList: response.data.data._selected_previous_pregnancies_list,
                    }
                    if (response.data.completed_pdf) {
                        referral_dict['previewLink'] = response.data.completed_pdf.url
                    }
                    commit(SET_IS_EDIT, true)
                    commit(SET_REFERRAL_DATA, referral_dict)
                    dispatch('setPageTransition', 'slideFade')
                    dispatch('setHomeTransition', 'popStay')
                    route.push({name: 'NewReferral'})

                })
                .catch((error) => {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Something has gone wrong retrieving a referral - ' + error.message
                    })
                    swal.fire(options)
                    throw error.message
                    })
        },
        [DELETE_REFERRAL_DRAFT]: ({commit}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/referral-record/' + payload.id + '/delete/')
                .then((response) => {
                    commit(DELETE_REFERRAL_DRAFT, payload)
                })
                .catch((error) => {
                    console.log(error)
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Something has gone wrong - ' + error.detail
                    })
                    swal.fire(options)
                    throw error.message
                })
        },
        [GET_REFERRALS_DRAFTS]: ({state, commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/referral-record/?draft=1&limit=999999&order=-modified')
                .then((response) => {
                    commit(SET_REFERRALS_DRAFTS, response.data.items)
                    commit(SET_REFERRALS_DRAFTS_TOTAL, response.data.meta.total_count)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [GET_REFERRAL_ERROR_COUNT]: ({commit}) => {
            return axios.get(axios.defaults.baseURL + '/api/v2/referral-record/?draft=0&limit=999999&flagged_as_completed=0&fields=_,flagged_as_completed')
                .then((response) => {
                    commit(SET_REFERRAL_ERROR_COUNT, response.data.meta.total_count)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [CREATE_REFERRAL]: ({state, commit}, payload) => {
            var attachments = false
            if (payload.attachments) {
                attachments = state.referralData.attachments
            }
            if (!payload.coverLetter) {
                payload.coverLetter = false
            }
            if (!payload.subject) {
                payload.subject = false
            }
            return axios.post(axios.defaults.baseURL + '/create-referral/' + payload.referral_id + '/', {
                    client_dict: payload.dictionary,
                    current_record_id: payload.current_record_id,
                    attachments: attachments,
                    cover_letter: payload.coverLetter,
                    subject: payload.subject
                },
                 {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
                })
            .then((response) => {
                // need to set rather than commit due to the page component loading before the promise returns
                vm.$set(state.referralData, 'previewLink', response.data.draft_file)
                vm.$set(state.referralData, 'recordID', response.data.record_id)
            })
            .catch((error) => {
                console.log(error)
                throw error.message
            })
        }, 
        [CREATE_REFERRAL_SIMPLE]: ({state, commit}, payload) => {
            var attachments = false
            if (payload.attachments) {
                attachments = state.referralData.attachments
            }
            return axios.post(axios.defaults.baseURL + '/create-referral-simple/' + payload.referral_id + '/', {
                    client_dict: payload.dictionary,
                    current_record_id: state.referralData.recordID,
                    attachments: attachments
                }, {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
                })
            .then((response) => {
                // need to set rather than commit due to the page component loading before the promise returns
                vm.$set(state.referralData, 'previewLink', response.data.draft_file)
                vm.$set(state.referralData, 'recordID', response.data.record_id)
            })
            .catch((error) => {
                console.log(error)
                throw error.message
            })
        },
        [SET_NUMBER_REQUIRED]: ({commit}, payload) => {
            commit(SET_NUMBER_REQUIRED, payload)
        },
        [SET_NUMBER_REMAINING]: ({commit}, payload) => {
            commit(SET_NUMBER_REMAINING, payload)
        },
        [SHOW_NUMBER_REQUIRED]: ({commit}, payload) => {
            commit(SHOW_NUMBER_REQUIRED, payload)
        },
        [REQUIRED_FIELD_JUMP_LIST]: ({commit}, payload) => {
            commit(REQUIRED_FIELD_JUMP_LIST, payload)
        },
        [SET_JUMP_LIST_NUMBER]: ({commit}, payload) => {
            commit(SET_JUMP_LIST_NUMBER, payload)
        },
        [SET_REFERRAL_SECTIONS_OPEN]: ({commit}, payload) => {
            commit(SET_REFERRAL_SECTIONS_OPEN, payload)
        },
        [SET_ATTEMPTED_SUBMIT]: ({commit}, payload) => {
            commit(SET_ATTEMPTED_SUBMIT, payload)
        },
        [RESET_REQUIRED]: ({commit}, payload) => {
            commit(SET_NUMBER_REQUIRED, 0)
            commit(SET_NUMBER_REMAINING, 0)
            commit(SHOW_NUMBER_REQUIRED, false)
            commit(SET_ATTEMPTED_SUBMIT, false)
            commit(SET_REFERRAL_SECTIONS_OPEN, [])
        },
        [UPDATE_REFERRAL_DATA]: ({commit}, payload) => {
            commit(UPDATE_REFERRAL_DATA, payload)
        },
        [SET_REFERRAL_DATA]: ({commit}, payload) => {
            commit(SET_REFERRAL_DATA, payload)
        },
        [RESET_REFERRAL_DATA]: ({commit}, payload) => {
            commit(SET_REFERRAL_DATA, {
                woman: null,
                child: null,
                referralSelected: null,
                formData: {},
                selectedPreviousPregnancies: {},
                selectedPreviousPregnanciesList: {},
            })
            commit(SET_SEND_TO_LIST, [])
        },
        [SEND_REFERRAL]: ({commit, state}, payload) => {
            var complex = state.referralData.referralSelected.referral.is_complex
            if (complex) {
                var referralRoute = '/create-referral/'
            } else {
                var referralRoute = '/create-referral-simple/'
            }
            var postPackage = {
                client_dict: state.referralData.formData,
                current_record_id: state.referralData.recordID,
                recipients: payload.recipients,
                is_completed: true,
                current_step: state.referralData.currentStep,
            }
            if (payload.markedSent) { 
                postPackage['markedSent'] = true
            }
            if (state.referralData.attachments) {
                postPackage['attachments'] = true
            }
            if (!payload.coverLetter) {
                postPackage['cover_letter'] = false
            } else {
                postPackage['cover_letter'] = payload.coverLetter
            }
            if (!payload.subject) {
                postPackage['subject'] = false
            } else {
                postPackage['subject'] = payload.subject
            }
            return axios.post(axios.defaults.baseURL + referralRoute + state.referralData.referralSelected.referral.id + '/', postPackage,
                {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
                })
            .then((response) => {
                var res = response.data
                var res_dict = {
                    saved: true,
                    referral: res.referral,
                    date: res.completed_date,
                    care_location: res.care_location.name,
                    completed_id: res.id,
                    send_to_results: res.send_to_results
                }
                commit(UPDATE_REFERRAL_DATA, {field: 'referralComplete', value: true})
                commit(UPDATE_REFERRAL_DATA, {field: 'referralResults', value: res_dict})
            })
            .catch((error) => {
                console.log('this is an error', error, error.response.data == true)
                if (error.response.data) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                    title: 'Oh no',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    // timer: 2000,
                    })
                    swal.fire(options)
                } else {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Something has gone wrong sending a referral - ' + error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
                throw error.message
            })
        },
        [RESEND_REFERRAL]: ({commit, state}, payload) => {
            var referralRoute = '/resend-referral/'
            var postPackage = {
                referral_record: payload.referral,
                recipients: payload.recipients,
            }
            if (!payload.coverLetter) {
                postPackage['cover_letter'] = false
            } else {
                postPackage['cover_letter'] = payload.coverLetter
            }
            if (!payload.subject) {
                postPackage['subject'] = false
            } else {
                postPackage['subject'] = payload.subject
            }
            return axios.post(axios.defaults.baseURL + referralRoute + payload.referral.id + '/', postPackage,
                {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
                })
            .then((response) => {
                commit(SET_SINGLE_REFERRAL, response.data)
            })
            .catch((error) => {
                console.log('this is an error', error, error.response.data == true)
                if (error.response.data) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                    title: 'Oh no',
                    // icon: 'error',
                    customClass: {
                        container: 'sweetalert-modal error',
                        popup: 'box-shadow-dark'
                    },
                    imageUrl: '/static/img/alert-error.svg',
                    text: error.response.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    })
                    swal.fire(options)
                } else {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Something has gone wrong sending a referral - ' + error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
                throw error.message
            })
        },
        [SET_STEP_NUMBER]: ({commit}, payload) => {
            commit(SET_STEP_NUMBER, payload)
        },
        [SET_SEND_TO_LIST]: ({commit}, payload) => {
            commit(SET_SEND_TO_LIST, payload)
        },
        [ADD_SEND_TO_LIST]: ({commit}, payload) => {
            commit(ADD_SEND_TO_LIST, payload)
        },
        [REMOVE_SEND_TO_LIST]: ({commit}, payload) => {
            commit(REMOVE_SEND_TO_LIST, payload)
        },
        [REMOVE_ATTACHMENT]: ({commit, state}, payload) => {
            return axios.post(axios.defaults.baseURL + '/api/v2/referral-record/remove-attachment/' + payload.referralId + '/' + payload.attachmentId + '/',
                {
                    headers: {'Content-Type': 'application/x-www-form-ulrencoded'} 
                })
            .then((response) => {
                vm.$set(state.referralData, 'attachments', response.data)
            })
            .catch((error) => {
                console.log('this is an error', error, error.response.data == true)
                if (error.response.data) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: error.response.data.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                } else {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert-error.svg',
                        text: 'Something has gone wrong removing the attachment - ' + error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
                throw error.message
            })
        },
        [MARK_REFERRAL_COMPLETED]: ({commit}, payload) => {
            var referralRoute = '/api/v2/referral-record/mark-completed/'
            return axios.post(axios.defaults.baseURL + referralRoute + payload.id + '/', payload,
                {
                    headers: {'Content-Type': 'application/x-www-form-ulrencoded'} 
                })
            .then((response) => {
                commit(SET_SINGLE_REFERRAL, response.data)
                // commit(MARK_REFERRAL_COMPLETED, response.data)
            })
            .catch((error) => {
                console.log('this is an error', error, error.response.data == true)
                if (error.response.data) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert error.svg',
                        text: error.response.data.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                } else {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert error.svg',
                        text: 'Something has gone wrong updating the referral record - ' + error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
                throw error.message
            })
        },
        [UPDATE_SELECTED_PREVIOUS_PREGNANCIES]: ({commit}, payload) => {
            commit(UPDATE_SELECTED_PREVIOUS_PREGNANCIES, payload)
        },
        [UPDATE_SELECTED_PREVIOUS_PREGNANCIES_LIST]: ({commit}, payload) => {
            commit(UPDATE_SELECTED_PREVIOUS_PREGNANCIES_LIST, payload)
        },
        [ASSIGN_REFERRAL]: ({commit}, payload) => {
            var referralRoute = '/api/v2/referral-record/assign/'
            return axios.post(axios.defaults.baseURL + referralRoute + payload.id + '/', payload)
            .then((response) => {
                commit(SET_SINGLE_REFERRAL, response.data)
                // commit(MARK_REFERRAL_COMPLETED, response.data)
            })
            .catch((error) => {
                console.log('this is an error', error, error.response.data == true)
                if (error.response.data) {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert error.svg',
                        text: error.response.data.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                } else {
                    var options = Object.assign({}, sweetalert_options)
                    options = Object.assign(options, {
                        title: 'Oh no',
                        // icon: 'error',
                        customClass: {
                            container: 'sweetalert-modal error',
                            popup: 'box-shadow-dark'
                        },
                        imageUrl: '/static/img/alert error.svg',
                        text: 'Something has gone wrong updating the referral record - ' + error.message,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                    })
                    swal.fire(options)
                }
                throw error.message
            })
        }
    }
}